import { useCallback } from 'react'

import { useRegisterDevice } from 'graphql/models/user/mutations/registerDevice'
import { DeviceType } from 'types/generated/_common'
import { getLocalStorage } from 'utils/storage'

const DEVICE_IDENTIFIER = 'DEVICE_IDENTIFIER'

export const useRegisterDeviceWithDeviceId = () => {
  const [registerDevice] = useRegisterDevice()

  return useCallback(async () => {
    const localStorage = getLocalStorage()
    const deviceIdentifier = localStorage.getItem(DEVICE_IDENTIFIER)

    try {
      const { data } = await registerDevice({
        variables: {
          input: {
            device: {
              ...(deviceIdentifier
                ? { deviceIdentifier: deviceIdentifier }
                : {}),
              deviceType: DeviceType.WEB,
            },
          },
        },
      })

      if (data?.registerDevice?.device?.deviceIdentifier) {
        localStorage.setItem(
          DEVICE_IDENTIFIER,
          data.registerDevice.device.deviceIdentifier
        )
      }
    } catch (err) {
      console.warn(err)
    }
  }, [registerDevice])
}
