import {
  MutationHookOptions,
  MutationTuple,
  TypedDocumentNode,
  useMutation,
} from '@apollo/client'

export function createMutationHook<Data, Vars>(
  query: TypedDocumentNode<Data, Vars>
) {
  return (
    options?: MutationHookOptions<Data, Vars>
  ): MutationTuple<Data, Vars> => useMutation(query, options)
}
