import styled from 'styled-components'

const MOBILE = 'MOBILE'
export const MOBILE_LANDSCAPE = 'MOBILE_LANDSCAPE'
export const TABLET = 'TABLET'
export const TABLET_LANDSCAPE = 'TABLET_LANDSCAPE'
export const DESKTOP = 'DESKTOP'

// for max-width, use `${MinimumWidths[SIZE] - 1}px`
export const MinimumWidths = {
  [MOBILE]: 0,
  [MOBILE_LANDSCAPE]: 480,
  [TABLET]: 768,
  [TABLET_LANDSCAPE]: 992,
  [DESKTOP]: 1280,
}

export const Breakpoints = {
  [MOBILE]: `(min-width: ${MinimumWidths[MOBILE]}px)`,
  [MOBILE_LANDSCAPE]: `(min-width: ${MinimumWidths[MOBILE_LANDSCAPE]}px)`,
  [TABLET]: `(min-width: ${MinimumWidths[TABLET]}px)`,
  [TABLET_LANDSCAPE]: `(min-width: ${MinimumWidths[TABLET_LANDSCAPE]}px)`,
  [DESKTOP]: `(min-width: ${MinimumWidths[DESKTOP]}px)`,
}

const Container = styled.div`
  margin: 0 auto;
  max-width: 568px;
  width: 100%;
  padding: 0 15px;

  @media ${Breakpoints[TABLET]} {
    max-width: 1280px;
    padding-left: 25px;
    padding-right: 25px;
  }
`

export default Container
