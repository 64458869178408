const BASE_URI = process.env.GATSBY_API_BASE_URL

module.exports = {
  newsLetter: `${BASE_URI}/newsletter/members/`,
  user: {
    login: `${BASE_URI}/auth/login/`,
    signup: `${BASE_URI}/auth/register/`,
    signout: `${BASE_URI}/auth/logout/`,
    refresh: `${BASE_URI}/auth/refresh/`,
    forgotpassword: `${BASE_URI}/auth/password/forgot/`,
    resetpassword: `${BASE_URI}/auth/password/reset/`,
  },
  pets: `${BASE_URI}/pets/:id*`,
  graphql: `${BASE_URI}/graphql/`,
}
