/*
When adding colors to this map, use
https://coolors.co/${hex_code}
to get the color name to add
*/
const Colors = {
  originalBlack: '#0E142C',
  black90: '#1A1A1A',
  black8: '#ECECEE',
  black65: '#585C6B',
  black48: '#8B8E9A',
  black72: '#4A4E60',
  blue: '#0465f2',
  green: '#1BB273',
  grey: '#808080',
  alabaster: '#FCFCFC',
  boxBlue: '#2A72D4',
  boxBlue65: '#709edf',
  boxBlue50: '#60A1FA',
  clinicBlue: '#042E65',
  beauBlue: '#D3E5FF',
  littleBeauBlue: '#5BA1FF',
  aliceBlue: '#e0edff',
  originalDenim: '#0e5ac0',
  teal: '#37A4B3',
  aeroBlue: '#D7FFEF',
  emergencyRed: '#E50909',
  tartOrange: '#FF4646',
  perksGreen: '#20686C',
  seaGreen: '#158657',
  rxBlue: '#174B93',
  independence: '#515566',
  yellow: '#ffc700',
  ghostWhite: '#F9FBFE',
  blackCoral: '#656978',
  borderGrey: '#D8D9DD',
  darkBlueGrey: '#818D9A',
  originalIris: '#504ED1',
  charmPink: '#EA8BAD',
  cultured: '#f4f4f4',
  sonicSilver: '#737373',
  gainsboro: '#E0E0E0',
  eerieBlack: '#262626',
  quickSilver: '#A5A5A5',
  davysGrey: '#5a5959',
  darkCultured: '#ededed',
  white: '#FFFFFF',
  lightGrey: '#d0d0d0',
  caribbeanGreen: '#32CF8E',
  skyBlueCrayola: '#80DCEB',

  /* &Walsh color scheme */
  walshBlack: '#161414',
  walshGrey: '#666565',
  walshOrange: '#E48900',
  walshOrange1: '#FFFBF5',
  walshOrange2: '#FFF5E7',
  walshOrange3: '#FFEBCE',
  walshOrange4: '#FFDDAB',
  walshOrange5: '#FFD18B',
  walshOrange6: '#FFC46B',
  walshOrange7: '#FAB54D',
  walshOrange8: '#F9A62A',
  walshOrange9: '#F09813',
  walshOrange10: '#E48900',
  walshPurple: '#7E6FDA',
  walshPurple1: '#F6F4FF',
  walshPurple2: '#EEEBFF',
  walshPurple3: '#E0DBFF',
  walshPurple4: '#D6CFFF',
  walshPurple5: '#CAC0FE;',
  purple: '#B2A5FF',
  walshPurple6: '#b2a5ff',
  walshPurple7: '#9182EC',
  walshPurple8: '#7E6FDA',
  walshPurple9: '#6B5CC8',
  walshPurple10: '#5C4CBF',
  floralWhite: '#FFFBF5',
  walshGreen1: '#F4FFFA',
  walshGreen2: '#E6FFF2',
  walshGreen3: '#D1FFE9',
  walshGreen4: '#C4FAE0',
  walshGreen5: '#ABF1CF',
  walshGreen6: '#A4E6C5',
  walshGreen7: '#88D6B0',
  walshGreen8: '#75C29D',
  walshGreen9: '#64AF8B',
  walshGreen10: '#428564',
  walshGrayscale1: '#F9F9F9',
  walshGrayscale2: '#F3F3F3',
  walshGrayscale3: '#ECECEC',
  walshGrayscale4: '#E2E2E2',
  walshGrayscale5: '#D3D3D3',
  walshGrayscale6: '#B7B7B7',
  walshGrayscale7: '#959595',
  walshGrayscale8: '#666565',
  walshGrayscale9: '#433E3E',
  walshGrayscale10: '#161414',
  walshRed1: '#FFF7F7',
  walshRed2: '#FFE8E8',
  walshRed3: '#FFD1D1',
  walshRed4: '#FFAEAE',
  walshRed5: '#FF8585',
  walshRed6: '#F96767',
  walshRed7: '#EA4D4D',
  walshRed8: '#D73A3A',
  walshRed9: '#B72626',
  walshRed10: '#940404',
  walshTeal1: '#F4FDFF',
  walshTeal2: '#e4faff',
  walshTeal3: '#D1F8FE',
  walshTeal4: '#C4FAE0',
  walshTeal5: '#ABF1CF',
  walshTeal6: '#9EE6F2',
  walshTeal7: '#88D6B0',
  walshTeal8: '#62CADB',
  walshTeal9: '#4AB9CB',
  walshTeal10: '#319EAF',
  walshTeal11: '#268796',
  walshPink1: '#FFF8FC',
  walshPink2: '#FFEEF8',
  walshPink3: '#FFE6F5',
  walshPink4: '#FFDEF2',
  walshPink5: '#FFD6EF',
  walshPink6: '#FAC9E7',
  walshPink7: '#DE9EC5',
  walshPink8: '#C27EA8',
  walshPink9: '#AE6993',
  walshPink10: '#98507C',
  walshYellow1: '#FFFCF2',
  walshYellow2: '#FFF8DC',
  walshYellow3: '#FFF3C4',
  walshYellow4: '#FFECA7',
  walshYellow5: '#FFE78B',
  walshYellow6: '#FFE06C',
  walshYellow7: ' #FDD952',
  walshYellow8: ' #FCD02B',
  walshYellow9: ' #F8C60B',
  walshYellow10: '#EDBB00',
  walshBlue1: '#F5F8FF',
  walshBlue2: '#EEF2FF',
  walshBlue3: '#E3EAFF',
  walshBlue4: '#CFDAFF',
  walshBlue5: '#B6C6FF',
  walshBlue6: '#9CB2FF',
  walshBlue7: '#7D98FA',
  walshBlue8: '#6280E9',
  walshBlue9: '#4C6BD7',
  walshBlue10: '#2241AD',
  walshAlertGreen1: '#F3FFF6',
  walshAlertGreen2: '#E4FFEA',
  walshAlertGreen3: '#CEFFD9',
  walshAlertGreen4: '#ADFFBF',
  walshAlertGreen5: '#99FAAE',
  walshAlertGreen6: '#92E9A5',
  walshAlertGreen7: '#6DD083',
  walshAlertGreen8: '#3DB658',
  walshAlertGreen9: '#299B42',
  walshAlertGreen10: '#19762D',

  russianViolet: '#14143f',
  lavenderBlue: '#C6C9FE',
  greenYellowCrayola: '#F3EF90',

  /*
  rebrand colors -
  not integrated into final Pawp 2.0 Colors
  */
  cornFlowerBlue: '#98BDE4',
  liberty: '#4F509C',

  brandGray1: '#EDEDEE',
  brandGray2: '#636363',
  brandGray8: '#666565',
  brandBlue1: '#e0eef9',
  brandBlue2: '#CCE2F5',
  brandBlue4: '#121222',
  brandPurple1: '#f7f7ff',
  brandPurple2: '#ebebff',
  brandPurple3: '#7778B3',

  pawpBlue1: '#f8fbff',
  pawpBlue2: '#e0edff',
  pawpBlue3: '#b7d5ff',
  pawpBlue5: '#60a1fa',
  pawpBlue6: '#2a72d4',
  pawpGray1: '#fcfcfc',
  pawpGray4: '#e0e0e0',
  pawpGray5: '#d0d0d0',
  pawpGray6: '#a5a5a5',
  pawpGray7: '#737373',
  pawpGray8: '#5a5959',

  greyscaleProduct10: '#313131',
  greyscaleProduct30: '#868686',
  greyscaleProduct70: '#D7D7D7',

  /* Pawp 2.0 Colors */
  black: '#141424',
  amethyst: '#13133D',
  prussian: '#7779B4',
  iris: '#4E519D',
  iris20D: '#3e417e',
  iris40L: '#9193cb',
  eggplant: '#3B2F70',
  mist: '#E4E0F0',
  mist15L: '#E8E5F2',
  mist60L: '#F4F3F9',
  mist10D: '#CBC6DA',
  orchid9: '#ddd8ff',
  orchid: '#9791C1',
  robinEgg: '#DEEEF9',
  robinEgg10D: '#B6D9F2',
  sky: '#97BDE4',
  sky10: '#001c65',
  denim: '#587EB5',
  darkWash: '#3E587F',
  midnight: '#1D3559',
  peach: '#F0AD91',
  coral2: '#ffedeb',
  coral3: '#F7BEB6',
  coral4: '#cf5543',
  coral: '#EC6B58',
  ember: '#C75A47',
  flamingo: '#EBACCD',
  blush: '#CA85B3',
  eggnog: '#F8F6F2',
  eggnog5D: '#EFEBE2',
  cream: '#F9FAE7',
  lemon: '#EFEC83',
  fawn: '#C2B74A',
  frost: '#EFF0F0',
  elephant: '#D4D5D4',

  /* System Colors */
  systemBlack: '#000000',
  systemWhite: '#FFFFFF',
  systemGray01: '#F2F2F7',
  systemGray02: '#E5E5EA',
  systemGray03: '#D1D1D6',
  systemGray04: '#C7C7CC',
  systemGray05: '#AEAEB2',
  systemGray06: '#8E8E93',
  systemGray07: '#636366',
  systemGray08: '#48484A',
  systemGray09: '#3A3A3C',
  systemGray10: '#2C2C2E',
  systemGray11: '#1C1C1E',
  systemRed20D: '#c01515',
  systemRed: '#E72323',
  systemRed40L: '#FF4B4B',
  systemRed90L: '#FDE9E9',
  systemGreen: '#03A678',
  systemGreen20D: '#028560',
  systemGreen10L: '#04C790',
  systemGreen90L: '#DDFEF5',
  systemOrange: '#FBA82D',
  systemOrange70L: '#FEE5C0',
  systemYellow: '#EAE661',

  /*
   * Specific case colors
   */
  triageBlue: '#14487A',
  triageGreen: '#4AA14B',
  triageOrange: '#DA6F31',
  triagePurple: '#8F12DB',
  triageRed: '#D74B2F',
  triageYellow: '#FCD03E',
  panelListItemValue: '#070707',
  vipGold: '#FFCD4F',
  vipGold80L: '#FFF0CB',
  lightBlue: '#BFE2F7',
  partnerBlue: '#F1F9FF',
  partnerRedemptionBlue: '#597EB6',
  partnerRedemptionOnboardingBlue: '#6CADD0',
} as const

export type ColorToken = keyof typeof Colors

export default Colors
