import gql from 'graphql-tag'
import { createMutationHook } from 'graphql/util/createMutationHook'
import {
  RegisterDevice,
  RegisterDeviceVariables,
} from 'types/generated/RegisterDevice'

const MUTATION_REGISTER_DEVICE = gql`
  mutation RegisterDevice($input: RegisterDeviceInput!) {
    registerDevice(input: $input) {
      device {
        id
        deviceIdentifier
      }
    }
  }
`

export const useRegisterDevice = createMutationHook<
  RegisterDevice,
  RegisterDeviceVariables
>(MUTATION_REGISTER_DEVICE)
