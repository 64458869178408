export const omitEmptyKeys = (obj: Record<string, any>): typeof obj => {
  return Object.entries(obj).reduce(
    (acc, [key, v]) =>
      v !== undefined && v !== '' && v !== null ? { ...acc, [key]: v } : acc,
    {}
  )
}

export function mapValues<
  InputType extends Record<string, any>,
  OutputType extends Record<string, any>
>(
  obj: InputType,
  mapFn: (
    value: InputType[keyof InputType],
    key: string
  ) => OutputType[keyof OutputType]
): OutputType {
  return Object.entries(obj).reduce((a, [key, v]) => {
    a[key as keyof OutputType] = mapFn(v, key)
    return a
  }, {} as OutputType)
}

export const objectInObject = (
  obj1: Record<string, any>,
  obj2: Record<string, any>
) => {
  return Object.keys(obj1).every((key) => obj2[key] === obj1[key])
}

export function satisfiesStruct<T extends Record<string, any>>(
  v: any,
  struct: T
): v is T {
  if (!v) return false
  if (typeof v !== 'object') return false
  for (const [key, value] of Object.entries(struct)) {
    if (!(key in v)) return false
    if (typeof value !== typeof v[key]) return false
  }

  return true
}
