import Cookies from 'js-cookie'

import { AttributionInput } from '_generated/gql/graphql'
import { SHARED_COOKIE_OPTIONS } from 'features/_common/components/molecules/AttributionManagement/AttributionManagement'
import { getSessionStorage } from 'utils/storage'

export default function getCheckoutAttributionInput():
  | AttributionInput
  | undefined {
  const sessionStorage = getSessionStorage()
  const inviteCode: string =
    Cookies.get('inviteCode') || sessionStorage.getItem('inviteCode') || ''

  let attributionFields: Record<string, string> = {}

  try {
    const attributionCookieJson = Cookies.get('attribution')
    if (attributionCookieJson) {
      attributionFields = JSON.parse(attributionCookieJson)
    }
  } catch (err) {
    Cookies.remove('attribution', SHARED_COOKIE_OPTIONS)
  }

  if (inviteCode) {
    const attribution: AttributionInput = {
      channel: 'referral',
      meta: JSON.stringify({
        referralCode: inviteCode,
        ...attributionFields,
      }),
    }

    return attribution
  }

  const channel = getAttributionSource()
  if (channel) {
    const awcId = getAttributionClickId()

    const attribution: AttributionInput = {
      channel: channel,
      meta: JSON.stringify(attributionFields),
    }

    if (awcId) {
      attribution.clickId = awcId
    }

    return attribution
  }

  return undefined
}

export function getAttributionSource(): string | undefined {
  const attribution = JSON.parse(Cookies.get('attribution') || '{}')

  // pid is used when using AppsFlyer urls
  return attribution.pid || attribution.utm_source
}

/**
 * Only used when using AWIN deeplink urls
 */
export function getAttributionClickId(): string | undefined {
  return Cookies.get('aw_click_id')
}

/*
 * Only used to pass utm medium through to appsflyer
 */
export function getAttributionMedium(): string | undefined {
  const attribution = JSON.parse(Cookies.get('attribution') || '{}')

  return attribution.af_channel || attribution.utm_medium
}

export function getPersistedInitialInteraction(): string | undefined {
  return Cookies.get('persistedInitialInteraction')
}

export function getAttributionCampagin(): string | undefined {
  const attribution = JSON.parse(Cookies.get('attribution') || '{}')

  return attribution.c || attribution.utm_campaign
}
