import React from 'react'
import { PropTypes as T } from 'prop-types'

const propTypes = {
  fillColor: T.string,
  className: T.string,
}

const defaultProps = {
  fillColor: '#7e7e7e',
  className: '',
}

export default function Close({ fillColor, className }) {
  return (
    <svg
      width="75"
      height="75"
      viewBox="0 0 75 75"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M74.7395 12.5159L50.0372 37.3698L74.7395 62.2236L61.8884 74.7395L37.3698 50.1547L12.9398 74.7395L0 62.2236L24.7023 37.3698L0 12.5159L12.9398 0L37.3698 24.5848L61.8884 0L74.7395 12.5159Z"
        fill={fillColor}
      />
    </svg>
  )
}

Close.propTypes = propTypes
Close.defaultProps = defaultProps
