/**
 * `homepage`: users who’s first interaction is the `homepage`
 * `emergency`: users who’s first interaction is pages with slug `/emergency` or `/app/get-started/protection/`
 * `online_vet`: users who’s first interaction is pages with slug `/online-vet-free` or `auth/signup/create-account/` or `/auth/signup/free-trial/`
 * `vet_finder`: users who’s first interaction is pages with slug `/vet-finder`
 * `gifting`: users who’s first interaction is pages with slug `/app/get-started/gifting/`
 * `referral`: users who’s first interaction is pages with slug `/auth/signup/referral/`
 * `perks`: users who’s first interaction is pages with slug `/benefits`
 * `pharmacy`: users who’s first interaction is pages with slug `/offers`
 * `pharmacy_condition`: users who’s first interaction is pages with slug `/app/get-started/flea-and-tick/`
 * `partnerships_offer`: users who’s first interaction is pages with slug `/auth/signup/{partner_name}`
 * `blog`: users who’s first interaction is the `blog homepage` or any blog `article`
 * `other`: anything else
 */

export default function getInitialInteraction(
  pathName: string,
  dynamicInitialIntractions: Record<string, string[]>
): string {
  const interactionUrlMatchPatterns: Record<string, string[]> = {
    homepage: ['/'],
    emergency: ['/app/get-started/protection/*'],
    online_vet: [
      '/online-vet-free/',
      '/auth/signup/create-account/',
      '/auth/signup/free-trial/',
    ],
    vet_finder: ['/vet-finder/'],
    gifting: ['/app/get-started/gifting/*'],
    referral: ['/auth/signup/referral/'],
    perks: ['/benefits/*'],
    pharmacy: ['/offers/*'],
    pharmacy_condition: ['/app/get-started/flea-and-tick/*'],
    partnerships_offer: [
      '/app/get-started/tuesdays/',
      ...dynamicInitialIntractions['partnerships_offer'],
    ],
    blog: [
      '/blog/',
      '/blog/search/',
      '/blog/author/*',
      ...dynamicInitialIntractions['blog'],
    ],
  }

  let interaction = 'other'

  for (const key in interactionUrlMatchPatterns) {
    let matched = false
    const patterns = interactionUrlMatchPatterns[key]

    for (const pattern of patterns) {
      const regex = new RegExp(`^${pattern.replace('/*', '/.*')}$`)
      if (regex.test(pathName)) {
        interaction = key
        matched = true
        break
      }
    }

    if (matched) {
      break
    }
  }

  return interaction
}
