import styled from 'styled-components'
import Colors from 'components/system/constants/Colors'
import Warning from 'features/_common/components/icons/Warning/Warning'

export const Wrapper = styled.div`
  color: ${Colors.emergencyRed};
  border: 1px solid ${Colors.emergencyRed};
  padding: 15px;
  background: #fff2f9;
  border-radius: 6px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
`

export const Icon = styled(Warning).attrs(() => ({
  color: Colors.emergencyRed,
  width: 20,
  height: 18,
}))`
  flex-grow: 1;
  min-width: 20px;
  max-width: 20px;
  margin-right: 15px;
`
