export const ROUTES = {
  LOGIN: '/auth/login',
  PASSWORD: '/auth/password/',
  OTP: '/auth/otp/',
  DASHBOARD: '/app/dashboard',
  SCHEDULE_VISIT: '/app/schedule-visit',
  ACCOUNT: '/app/account',
  ACCOUNT_PROFILE: '/app/account/profile',
  ACCOUNT_DOCUMENTS: '/app/account/documents',
  ACCOUNT_MEMBERSHIP: '/app/account/membership',
  ACCOUNT_MEMBERSHIP_ACTIVATE: '/app/account/membership/activate',
  ACCOUNT_BILLING: '/app/account/billing',
  CANCEL_PLAN: '/app/form/cancelation',
  CANCEL_EF_ADDON: '/app/form/ef_cancelation',
  EMERGENCY_FUND: '/app/emergency-fund',
  EMERGENCY_FUND_POLICY: '/app/emergency-fund/policy',
  EMERGENCY_FUND_DOCUMENTS: '/app/emergency-fund/documents',
  EMERGENCY_FUND_INSTRUCTIONS: '/app/emergency-fund/instructions',
  PETS: '/app/pets',
  PETS_PROFILE: '/app/pets/:petId',
  CLINIC: '/app/clinic',
  CLINIC_POST_CONVERSATION: '/app/clinic/post-conversation/:conversationId',
  CLINIC_WAITING_ROOM: '/app/clinic/waiting-room/:conversationId',
  CLINIC_CONVERSATION: '/app/clinic/conversation/:conversationId',
  CLINIC_VISITS: '/app/visits',
  CLINIC_VISITS_COMPLETED: '/app/visits/completed',
  CLINIC_VISITS_COMPLETED_ID: '/app/visits/completed/:visitId',
  CLINIC_VISITS_UPCOMING: '/app/visits/upcoming',
  CLINIC_VISITS_UPCOMING_ID: '/app/visits/upcoming/:visitId',
  PAWP_PERKS_MANAGEMENT_DASHBOARD: '/app/management',
  PHARMACY_HOME: '/app/pharmacy',
  PHARMACY_LIST: '/app/pharmacy/products',
  PHARMACY_LIST_PRODUCT: '/app/pharmacy/products/:productId',
  ORDER_HISTORY: '/app/orders',
  ORDER_HISTORY_ORDER: '/app/orders/:orderId',
  FLEA_AND_TICK_ONBOARDING: '/app/get-started/flea-and-tick',
  FLEA_AND_TICK_ONBOARDING_INTAKE: '/app/get-started/flea-and-tick/intake',
  FLEA_AND_TICK_ONBOARDING_REVIEW:
    '/app/get-started/flea-and-tick/review/:petId',
  FLEA_AND_TICK_ONBOARDING_CHECKOUT:
    '/app/get-started/flea-and-tick/checkout/:petId',
  FLEA_AND_TICK_ONBOARDING_SUCCESS:
    '/app/get-started/flea-and-tick/success/:addOnId',
  DYNAMIC_FORM: '/app/form/:formSlug',
  ONBOARDING_REVIEW: '/app/review/:onboardingSlug',
  ONBOARDING_CHECKOUT: '/app/checkout/:onboardingSlug',
  ONBOARDING_PARTNER_CHECKOUT: '/app/checkout/partner/:onboardingSlug',
  SMS_OPT_IN: '/app/sms',
  SYMPTOM_CHECKER_RESULT: '/app/clinic/pet-symptom-result/:id',
  PARTNER_REDEMPTION_INTAKE: '/partner/:partnerSlug',

  /** @deprecated: Redirect to extracted pro app **/
  PROFESSIONALS_PHARMACY: '/app/professionals/pharmacy',
  /** @deprecated: Redirect to extracted pro app **/
  PROFESSIONALS_PHARMACY_PRODUCT: '/app/professionals/pharmacy/:productId',
  /** @deprecated: Redirect to extracted pro app **/
  PROFESSIONALS_CONVERSATIONS: '/app/professionals/conversations',
  /** @deprecated: Redirect to extracted pro app **/
  PROFESSIONALS_CONVERSATIONS_ID:
    '/app/professionals/conversations/:conversationId',
  /** @deprecated: Redirect to extracted pro app **/
  PROFESSIONALS_APPOINTMENTS: '/app/professionals/appointments',
  /** @deprecated: Redirect to extracted pro app **/
  PROFESSIONALS_APPOINTMENTS_ID:
    '/app/professionals/appointments/:appointmentId',
  /** @deprecated: use CLINIC_VISITS */
  CLINIC_HISTORY: '/app/histories',
  /** @deprecated: use CLINIC_VISITS_COMPLETED_ID */
  CLINIC_HISTORIES_ID: '/app/histories/:visitId',
  ADDRESS_REQUIRED: '/app/address-required/',
  BASIC_USER_INFO_REQUIRED: '/app/user-info-required/',
} as const
