import { TypePolicy } from '@apollo/client'

import { conversation } from './conversation'
import { conversations } from './conversations'
import { unmatchedConversationsV2 } from './unmatchedConversationsV2'

export const Query: TypePolicy = {
  fields: {
    conversation,
    conversations,
    unmatchedConversationsV2,
  },
}
