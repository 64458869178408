import React from 'react'
import { PropTypes as T } from 'prop-types'

const propTypes = {
  width: T.number,
  height: T.number,
  color: T.string,
  alt: T.string,
  className: T.string,
}
const defaultProps = {
  width: 20,
  height: 18,
  color: '#808080',
  alt: 'warning',
  className: '',
}

const Warning = ({ width, height, color, alt, className }) => (
  <svg
    width={width}
    height={height}
    viewBox="0 0 20 18"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    className={className}
  >
    <title>{alt}</title>
    <path
      d="M2.92738 17.6204C1.918 17.6204 0.986759 17.0704 0.459379 16.2071C-0.135161 15.2313 -0.135161 14.0649 0.358597 13.0439L6.8984 2.3431C7.63902 1.25482 8.60308 0.379822 10.0054 0.379822C11.4077 0.379822 12.3726 1.25482 13.1124 2.3431L19.6186 12.9885C20.1569 13.9869 20.1342 15.2431 19.5397 16.2081C19.0124 17.0722 18.0928 17.6214 17.0717 17.6214H2.92709L2.92738 17.6204ZM10.0056 12.0344C10.6001 12.0344 11.0712 12.5164 11.0712 13.1109C11.0712 13.7055 10.6001 14.1766 10.0056 14.1766C9.41106 14.1766 8.92902 13.7055 8.92902 13.1109C8.92902 12.5164 9.41106 12.0344 10.0056 12.0344ZM10.0056 10.9688C9.61262 10.9688 9.33292 10.6438 9.28762 10.2508L8.92902 7.0312C8.87277 6.4812 9.45636 5.96558 10.0056 5.96558C10.5556 5.96558 11.1384 6.4812 11.0712 7.0312L10.7126 10.2508C10.679 10.632 10.4095 10.9688 10.0056 10.9688H10.0056ZM2.92738 16.1962H17.072C17.5993 16.1962 18.0478 15.9048 18.3282 15.4555C18.6532 14.9173 18.6423 14.2665 18.3845 13.7055L11.9235 3.13914C11.4633 2.46648 10.8914 1.80398 10.0055 1.80398C9.11957 1.80398 8.53597 2.4657 8.07583 3.13914L1.62583 13.7055C1.35629 14.2774 1.34537 14.9173 1.68208 15.4555C1.95162 15.904 2.40004 16.1962 2.9274 16.1962H2.92738Z"
      fill={color}
    />
  </svg>
)

Warning.propTypes = propTypes
Warning.defaultProps = defaultProps

export default Warning
