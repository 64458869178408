import { useContext, useCallback, useMemo } from 'react'
import {
  InMemoryCache,
  ApolloClient,
  ApolloLink,
  Observable,
  NormalizedCacheObject,
  createHttpLink,
} from '@apollo/client'

import { setContext } from '@apollo/client/link/context'
import { onError } from '@apollo/client/link/error'
import { createUploadLink } from 'apollo-upload-client'
import apiRoutes from 'data/apiroutes'
import {
  getRefreshToken,
  removeAllTokens,
  getGuestTokens,
  getAccessToken,
} from 'services/user'
import { GraphQLError } from 'graphql'
import UserContext from 'context/UserContext'
import iFetch from 'isomorphic-fetch'
import { ConversationType } from 'graphql/util/cacheOptions/ConversationType'
import { Query } from 'graphql/util/cacheOptions/Query/Query'
import { UserType } from 'graphql/util/cacheOptions/UserType'
import { MUTATION_REFRESH_TOKENS } from 'graphql/auth/mutations/refreshTokens'
import unions from '_generated/gql/possibleTypes'

interface authorizationHeaders {
  authorization: string
}
interface guestAuthorizationHeaders {
  'Guest-Identifier': string
  'Guest-Token': string
}

interface PawpGraphQLError extends GraphQLError {
  code?: string
}

const cache = new InMemoryCache({
  addTypename: true,
  typePolicies: {
    Query,
    UserType,
    ConversationType,
  },
  possibleTypes: unions.possibleTypes,
})

const refreshTokenHttpLink = createHttpLink({
  uri: apiRoutes.graphql,
})

const refreshTokenApolloClient = new ApolloClient({
  link: refreshTokenHttpLink,
  cache,
})

let refreshTokenSingleton: Promise<string> | undefined
export default function useApolloClient(): {
  client: ApolloClient<NormalizedCacheObject>
} {
  const { authenticate, authenticateGuest } = useContext(UserContext)

  // Temporary forced typing because of a syntactical difference in apollo-upload-client's ApolloLink definition
  const httpLink = createUploadLink({
    fetch: iFetch,
    uri: apiRoutes.graphql,
  }) as any as ApolloLink

  const onAuthFailure = useCallback(() => {
    removeAllTokens()
    authenticate(false)
    authenticateGuest(false)
  }, [authenticate, authenticateGuest])

  const createRefreshTokenHandler = useCallback(() => {
    if (!refreshTokenSingleton) {
      refreshTokenSingleton = new Promise((resolve, reject) => {
        try {
          const refreshToken = getRefreshToken()

          if (refreshToken) {
            refreshTokenApolloClient
              .mutate({
                variables: {
                  refreshToken: refreshToken,
                },
                mutation: MUTATION_REFRESH_TOKENS,
              })
              .then(({ data }) => {
                if (data?.refreshTokens) {
                  authenticate(true, data.refreshTokens)
                  resolve(data.refreshTokens.accessToken)
                }
              })
              .catch((error) => {
                onAuthFailure()
                reject(error)
              })
          } else {
            onAuthFailure()
            reject(new Error('Could not get a refresh token.'))
          }
        } catch (err) {
          onAuthFailure()
          reject(err)
        }
      })

      refreshTokenSingleton
        .then(() => {
          refreshTokenSingleton = undefined
        })
        .catch(() => {
          onAuthFailure()
          refreshTokenSingleton = undefined
        })
    }

    return refreshTokenSingleton
  }, [authenticate, onAuthFailure])

  const errorLink = useMemo(
    () =>
      onError(({ networkError, graphQLErrors, forward, operation }) => {
        let isTokenExpired = false

        if (graphQLErrors) {
          graphQLErrors.forEach(
            ({ message, locations, path, code = '' }: PawpGraphQLError) => {
              const errorMessage = `[GraphQL error]: Message: ${message}, Location: ${locations}, Path: ${path}`

              if (
                code === 'expired_auth_token' ||
                code === 'guest_user_not_found'
              ) {
                isTokenExpired = true
                /* eslint-disable-next-line no-console */
                console.warn(errorMessage)
              } else {
                /* eslint-disable-next-line no-console */
                console.error(errorMessage)
              }
            }
          )
        }

        if (networkError) {
          /* eslint-disable-next-line no-console */
          console.error(networkError)
        }

        if (isTokenExpired) {
          const authToken = getAccessToken()
          const guestTokens = getGuestTokens()
          if (authToken) {
            return new Observable((observer) => {
              createRefreshTokenHandler()
                .then((newAccessToken) => {
                  if (newAccessToken) {
                    operation.setContext({
                      headers: {
                        ...operation.getContext().headers,
                        authorization: `Bearer ${newAccessToken}`,
                      },
                    })

                    const subscriber = {
                      next: observer.next.bind(observer),
                      error: observer.error.bind(observer),
                      complete: observer.complete.bind(observer),
                    }

                    // Retry last failed request
                    return forward(operation).subscribe(subscriber)
                  }
                })
                .catch((error) => {
                  /* eslint-disable-next-line no-console */
                  console.error(error)
                  onAuthFailure()
                  refreshTokenSingleton = undefined
                })
            })
          } else if (guestTokens) {
            onAuthFailure()
          }
        }
      }),
    [createRefreshTokenHandler, onAuthFailure]
  )

  const authLink = useMemo(
    () =>
      setContext((_, { headers }) => {
        const authToken = getAccessToken()
        const guestTokens = getGuestTokens()
        let authHeaders: authorizationHeaders | guestAuthorizationHeaders = {
          authorization: '',
        }

        if (guestTokens) {
          authHeaders = {
            'Guest-Identifier': guestTokens.identifier,
            'Guest-Token': guestTokens.token,
          }
        }

        if (authToken) {
          authHeaders = { authorization: `Bearer ${authToken}` }
        }

        return {
          headers: {
            ...headers,
            ...authHeaders,
          },
        }
      }),
    []
  )

  const client = useMemo(() => {
    const link = ApolloLink.from([errorLink, authLink, httpLink])
    return new ApolloClient({
      cache,
      link,
    })
  }, [authLink, errorLink, httpLink])

  return { client }
}
