import {
  LazyQueryHookOptions,
  OperationVariables,
  QueryHookOptions,
  QueryResult,
  TypedDocumentNode,
  useLazyQuery,
  useQuery,
} from '@apollo/client'
import { useEffect, useState } from 'react'

export function createQueryHook<Data, Vars extends OperationVariables>(
  query: TypedDocumentNode<Data, Vars>
) {
  return (options?: QueryHookOptions<Data, Vars>): QueryResult<Data, Vars> =>
    useQuery(query, options)
}

export function createLazyQueryHook<Data, Vars extends OperationVariables>(
  query: TypedDocumentNode<Data, Vars>
) {
  return (options?: LazyQueryHookOptions<Data, Vars>) =>
    useLazyQuery(query, options)
}

export function createStubbedQueryHook<Data, Vars extends OperationVariables>(
  stub: Data
) {
  return (): QueryResult<Data, Vars> => {
    const [data, setData] = useState<Data | undefined>(undefined)
    const [loading, setIsLoading] = useState(true)

    useEffect(() => {
      const timeout = setTimeout(() => {
        setIsLoading(false)
        setData(stub)
      }, 1500)

      return () => clearTimeout(timeout)
    }, [])

    return { data, loading } as QueryResult<Data, Vars>
  }
}
