import React, { useEffect } from 'react'

import { useUserContext } from 'context/UserContext'
import { useRegisterDeviceWithDeviceId } from './useRegisterDeviceWithDeviceId'

export const DeviceManagement: React.FC = () => {
  const { isAuth, isGuest } = useUserContext()
  const registerDeviceWithDeviceId = useRegisterDeviceWithDeviceId()

  useEffect(() => {
    if (isAuth || isGuest) {
      registerDeviceWithDeviceId()
    }
  }, [registerDeviceWithDeviceId, isAuth, isGuest])

  return <></>
}
