import React from 'react'
import { ThemeProvider } from 'styled-components'
import constants from 'data/constants'
import { UserContextProvider } from 'context/UserContext'
import ApolloProvider from 'context/Apollo'
import SentryErrorBoundary from 'components/errorboundaries/SentryErrorBoundary'
import UserManagement from 'features/_common/components/molecules/UserManagement/UserManagement'
import AttributionManagement from 'features/_common/components/molecules/AttributionManagement/AttributionManagement'
import { PromoCodeParser } from 'features/_common/components/molecules/PromoCodeManagement/PromoCodeParser'
import FlagProvider from '@unleash/proxy-client-react'
import { DeviceManagement } from 'features/_common/components/molecules/DeviceManagement/DeviceManagement'

const unleashConfig = {
  url: process.env.GATSBY_UNLEASH_PROXY_URL,
  clientKey: process.env.GATSBY_UNLEASH_PROXY_CLIENT_KEY,
  refreshInterval: 15,
  appName: 'web',
  environment: process.env.GATSBY_UNLEASH_ENVIRONMENT,
}

export default function RootElementWrapper({ element }) {
  return (
    <SentryErrorBoundary>
      <ThemeProvider theme={constants}>
        <UserContextProvider>
          <ApolloProvider>
            <FlagProvider config={unleashConfig}>
              <>
                <PromoCodeParser />
                <AttributionManagement />
                <UserManagement />
                <DeviceManagement />
                {element}
              </>
            </FlagProvider>
          </ApolloProvider>
        </UserContextProvider>
      </ThemeProvider>
    </SentryErrorBoundary>
  )
}
