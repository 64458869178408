import styled, { css } from 'styled-components'

export const H5CSS = css`
  font-size: 14px;
  font-weight: 800;
  line-height: 22px;
`

const H5 = styled.h5`
  ${H5CSS};
  min-height: 22px;
  display: block;
  margin: 0 0 5px 0;
  padding: 0;
  width: 100%;
`

export default H5
