class MockStorage {
  _cache = {}
  get length() {
    return Object.keys(this._cache).length
  }
  setItem = (name, value) => {
    this._cache[name] = value
  }
  getItem = (name) => {
    return this._cache[name]
  }
  removeItem = (name) => {
    delete this._cache[name]
  }
  clear = () => {
    this._cache = {}
  }
}

const mockStorage = new MockStorage()

export function getLocalStorage() {
  try {
    if (
      typeof window !== 'undefined' &&
      'localStorage' in window &&
      window.localStorage !== null
    ) {
      return window.localStorage
    } else {
      return mockStorage
    }
  } catch (err) {
    return mockStorage
  }
}

export function getSessionStorage() {
  try {
    if (
      typeof window !== 'undefined' &&
      'sessionStorage' in window &&
      window.sessionStorage !== null
    ) {
      return window.sessionStorage
    } else {
      return mockStorage
    }
  } catch (err) {
    return mockStorage
  }
}
