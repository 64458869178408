import { FieldPolicy, Reference } from '@apollo/client'
import { uniqueItems } from 'utils/list'

export const conversations: FieldPolicy = {
  keyArgs: ['statuses'],
  read(existing, { readField }) {
    if (!existing) return existing
    const conversations: Reference[] = existing.conversations.slice(0)

    const sortedConversations = conversations.sort((a, b) => {
      let aSortValue = readField('endedAt', a)
      let bSortValue = readField('endedAt', b)
      aSortValue = aSortValue || readField('createdAt', a) || 0
      bSortValue = bSortValue || readField('createdAt', b) || 0
      return aSortValue < bSortValue ? 1 : -1
    })
    return { ...existing, conversations: sortedConversations }
  },
  merge(existing, incoming, { args, readField }) {
    // If we have no cached value, return the incoming response
    if (!existing) return incoming
    // If the incoming response comes back null (in an error state), return the cached response
    if (!incoming) return existing

    // If we're not specifying page, return the latest (polled queries)
    if (!args?.page) return incoming

    // Otherwise, merge the two lists together, filtering out items from the existing cache that don't match the filters
    const allConversations = [...incoming.conversations]

    const { statuses } = args || {}
    for (const conversation of existing.conversations) {
      if (statuses && !statuses.includes(readField('status', conversation))) {
        continue
      }

      allConversations.push(conversation)
    }

    const hasNext = existing.hasNext !== false && incoming.hasNext !== false // polling makes it such that we might have fetched the same page multiple times
    return {
      ...incoming,
      conversations: uniqueItems(allConversations, '__ref'),
      hasNext,
    }
  },
}
