module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-axe-core-react/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-styled-components/gatsby-browser.js'),
      options: {"plugins":[],"displayName":true,"fileName":true,"minify":true,"namespace":"","transpileTemplateLiterals":true,"topLevelImportPaths":[],"pure":false,"disableVendorPrefixes":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-catch-links/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://pawp.com"},
    },{
      plugin: require('../node_modules/gatsby-plugin-nprogress/gatsby-browser.js'),
      options: {"plugins":[],"color":"#000","showSpinner":false},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Pawp","short_name":"Pawp","description":"A 24/7 online clinic for your pet with emergency coverage. Get veterinary advice & help paying vet bills. Talk to a veterinary professional now.","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"browser","icon":"./static/favicon/favicon.png","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"e58d0556eacaf6273e5f5e05454bdde7"},
    },{
      plugin: require('../node_modules/@sentry/gatsby/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby-plugin-segment-js/gatsby-browser.js'),
      options: {"plugins":[],"prodKey":"atfsvopJoJKAY0YcqqNkgzjUWXiQglsj","delayLoad":true,"host":"https://cdn-segment.pawp.com","trackPage":false},
    },{
      plugin: require('../plugins/pawp-third-party-loader-plugin/gatsby-browser.js'),
      options: {"plugins":[],"fsOrgId":"Q5QMD","appsflyerWebDevKey":"7b63e53e-39a5-4832-89a3-591ca3b32a47","gtmTagID":"GTM-T6W7D6S","gtmExcludePaths":["/partner/"],"customerIoSiteId":"2659bd30a176be89282a"},
    },{
      plugin: require('../node_modules/gatsby-plugin-use-query-params/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
