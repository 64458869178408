import objectFitImages from 'object-fit-images'
import RootElementWrapper from 'features/_common/components/layouts/RootElementWrapper/RootElementWrapper'

export const onInitialClientRender = () => {
  objectFitImages()

  // oof this is super breakable code, but should start segment earlier without
  // slowing page loads. see plugin: gatsby-plugin-segment-js
  if (window.segmentSnippetLoader) {
    'requestIdleCallback' in window
      ? requestIdleCallback(function () {
          window.segmentSnippetLoader()
        })
      : window.segmentSnippetLoader()
  }
}

export const onClientEntry = async () => {
  if (typeof IntersectionObserver === 'undefined') {
    await import('intersection-observer')
    console.log('IntersectionObserver polyfilled ;)')
  }
}

export const wrapRootElement = RootElementWrapper
