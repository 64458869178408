import React, { createContext, useState, useCallback, useContext } from 'react'
import { navigate } from '@reach/router'

import * as userService from 'services/user'

type authenticateFunction = (
  isAuthenticated: boolean,
  tokens?: {
    idToken: string
    accessToken: string
    refreshToken?: string | null
  } | null
) => void
type authenticateGuestFunction = (
  isAuthenticated: boolean,
  tokens?: Record<string, string>
) => void

interface UserContextType {
  isAuth: boolean
  isGuest: boolean
  authenticate: authenticateFunction
  authenticateGuest: authenticateGuestFunction
  signOut: () => void
}

export const UserContext = createContext<UserContextType>({
  isAuth: false,
  isGuest: false,
  authenticate: () => null,
  authenticateGuest: () => null,
  signOut: () => null,
})

export default UserContext

export const useUserContext = (): UserContextType => useContext(UserContext)

interface UserContextProviderProps {
  children: React.ReactNode
}

export function UserContextProvider({
  children,
}: UserContextProviderProps): JSX.Element {
  const authToken = userService.getAccessToken()
  const guestTokens = userService.getGuestTokens()

  const [isAuth, setIsAuth] = useState(authToken ? true : false)
  const [isGuest, setIsGuest] = useState(!isAuth && guestTokens ? true : false)

  const authenticate = useCallback<authenticateFunction>(
    (isAuthenticated, tokens) => {
      if (isAuthenticated && tokens) {
        userService.persistTokens(tokens)
        userService.removeGuestTokens()
      }
      setIsAuth(isAuthenticated)
      setIsGuest(false)
    },
    []
  )

  const authenticateGuest = useCallback<authenticateGuestFunction>(
    (isAuthenticated, tokens) => {
      if (isAuthenticated && tokens) {
        userService.setGuestTokens(tokens)
        setIsAuth(false)
      }

      setIsGuest(isAuthenticated)
    },
    []
  )

  const onSignOut = useCallback(async () => {
    // wrapping a sign out call in a try/catch statement in order to still
    // sign out a user even back-end will respond with a failure
    try {
      userService.removeAllTokens()
      setIsAuth(false)
      navigate('/')
    } catch (err) {
      // check for 401 maybe?
      userService.removeAllTokens()
      setIsAuth(false)
      navigate('/')
    }
  }, [])

  return (
    <UserContext.Provider
      value={{
        isAuth,
        isGuest,
        authenticate,
        authenticateGuest,
        signOut: onSignOut,
      }}
    >
      {children}
    </UserContext.Provider>
  )
}
