import styled, { css } from 'styled-components'

export const ParagraphCaptionCSS = css`
  font-size: 12px;
  line-height: 22px;
  font-weight: 500;
`

const ParagraphCaption = styled.p`
  ${ParagraphCaptionCSS}
  min-height: 22px;
  display: block;
  margin: 0 0 5px 0;
  padding: 0;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`

export default ParagraphCaption
