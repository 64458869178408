import React, { useEffect } from 'react'
import Cookies, { CookieAttributes } from 'js-cookie'

import { parseURLParams } from 'utils/url'

export const PromoCodeParser = (): JSX.Element => {
  useEffect(() => {
    const urlParams = parseURLParams(window.location.search)
    const { promo_code } = urlParams

    const cookieOptions: CookieAttributes = {
      expires: 30,
      path: '/',
      sameSite: 'strict',
      secure: window.location.protocol === 'https:',
    }

    if (promo_code) {
      Cookies.set('promo_code', promo_code.toUpperCase(), cookieOptions)
    }
  }, [])

  return <></>
}
