export function isDevelopment() {
  return process.env.NODE_ENV === 'development'
}

export function getEnvironment() {
  if (isDevelopment()) {
    return 'local'
  } else if (process.env.GATSBY_API_BASE_URL?.includes('stg')) {
    return 'stg'
  } else {
    return 'prod'
  }
}
