import React, { Component } from 'react'
import * as Sentry from '@sentry/gatsby'
import WarningBox from 'features/_common/components/molecules/WarningBox/WarningBox'
import H5 from 'components/system/Type/H5'
import ParagraphCaption from 'components/system/Type/ParagraphCaption'
import * as styles from './styles'

export default class SentryErrorBoundary extends Component {
  constructor(props) {
    super(props)

    this.state = { error: null }
  }

  componentDidCatch(error, errorInfo) {
    this.setState({ error })

    console.log(error)
    console.log(errorInfo)

    Sentry.configureScope((scope) => {
      Object.keys(errorInfo).forEach((key) => {
        scope.setExtra(key, errorInfo[key])
      })
    })
    Sentry.captureException(error, { extra: { errorInfo } })
  }

  render() {
    if (this.state.error) {
      // render fallback UI
      return (
        <>
          <styles.FloatingBox>
            <WarningBox>
              <styles.BoxContent>
                <styles.RefreshButton
                  onClick={() => window.location.reload()}
                  aria-label="refresh the page"
                >
                  <styles.CloseIcon />
                </styles.RefreshButton>
                <H5>Looks like something went wrong!</H5>
                <ParagraphCaption>Try refreshing the page.</ParagraphCaption>
              </styles.BoxContent>
            </WarningBox>
          </styles.FloatingBox>
          <>{this.props.children}</>
        </>
      )
    }
    // when there's not an error, render children untouched
    return this.props.children
  }
}
