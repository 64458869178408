import Cookies, { CookieAttributes } from 'js-cookie'

import { PAWP_DOMAIN } from 'constants/cookies'
import { getEnvironment, isDevelopment } from 'utils/env'
import { getLocalStorage, getSessionStorage } from 'utils/storage'

type TokensState = {
  guestTokens: Record<string, string | number> | null
  authTokens: {
    id_token: string
    access_token: string
    refresh_token: string
  } | null
}

const ENVIRONMENT = getEnvironment()
const APP_USER_TYPE = 'member'

const ID_TOKEN_COOKIE_NAME = `${ENVIRONMENT}_${APP_USER_TYPE}_id_token`
const REFRESH_TOKEN_COOKIE_NAME = `${ENVIRONMENT}_${APP_USER_TYPE}_refresh_token`
const ACCESS_TOKEN_COOKIE_NAME = `${ENVIRONMENT}_${APP_USER_TYPE}_access_token`
const GUEST_TOKENS_COOKIE_NAME = `${ENVIRONMENT}_${APP_USER_TYPE}_guest_tokens`

const ID_TOKEN_STORAGE_KEY = 'id_token'
const REFRESH_TOKEN_STORAGE_KEY = 'refresh_token'
const ACCESS_TOKEN_STORAGE_KEY = 'access_token'
const GUEST_TOKENS_STORAGE_KEY = 'guest_tokens'

const cookieAttributes: CookieAttributes =
  typeof window !== 'undefined'
    ? {
        ...(isDevelopment() ? {} : { domain: PAWP_DOMAIN }),
        secure: window.location.protocol === 'https:',
        sameSite: 'strict',
        expires: 365,
      }
    : {}

let tokensState: TokensState = { guestTokens: null, authTokens: null }

export const getCogIdToken = () =>
  Cookies.get(ID_TOKEN_COOKIE_NAME) || tokensState.authTokens?.id_token

export const getRefreshToken = () =>
  Cookies.get(REFRESH_TOKEN_COOKIE_NAME) ||
  tokensState.authTokens?.refresh_token

export const getAccessToken = () =>
  Cookies.get(ACCESS_TOKEN_COOKIE_NAME) || tokensState.authTokens?.access_token

interface Tokens {
  id_token?: string
  refresh_token?: string
  access_token?: string
  token_id?: string
  token_refresh?: string
  token_access?: string
  idToken: string
  accessToken: string
  refreshToken?: string | null
}

export const persistTokens = (tokens: Tokens) => {
  const {
    id_token = '',
    refresh_token = '',
    access_token = '',
    token_id = '',
    token_refresh = '',
    token_access = '',
    idToken,
    accessToken,
    refreshToken,
  } = tokens

  const id = idToken || id_token || token_id
  const refresh = refreshToken || refresh_token || token_refresh
  const access = accessToken || access_token || token_access
  Cookies.set(ID_TOKEN_COOKIE_NAME, id, cookieAttributes)

  if (refresh) {
    Cookies.set(REFRESH_TOKEN_COOKIE_NAME, refresh, cookieAttributes)
  }
  if (access) {
    Cookies.set(ACCESS_TOKEN_COOKIE_NAME, access, cookieAttributes)
  }

  if (id && access && refresh) {
    tokensState.authTokens = {
      id_token: id,
      access_token: access,
      refresh_token: refresh,
    }
  }
}

export const removeAllTokens = () => {
  const localStorage = getLocalStorage()
  const sessionStorage = getSessionStorage()

  Cookies.remove(ID_TOKEN_COOKIE_NAME, cookieAttributes)
  Cookies.remove(REFRESH_TOKEN_COOKIE_NAME, cookieAttributes)
  Cookies.remove(ACCESS_TOKEN_COOKIE_NAME, cookieAttributes)
  Cookies.remove(GUEST_TOKENS_COOKIE_NAME, cookieAttributes)
  localStorage.removeItem(ID_TOKEN_STORAGE_KEY)
  localStorage.removeItem(REFRESH_TOKEN_STORAGE_KEY)
  localStorage.removeItem(ACCESS_TOKEN_STORAGE_KEY)
  localStorage.removeItem(GUEST_TOKENS_STORAGE_KEY)
  localStorage.removeItem('tos-sep-2')
  sessionStorage.removeItem('my-pet/currentPetId')
  sessionStorage.removeItem('onboarding/box/user')
  sessionStorage.removeItem('checkout-user')
  sessionStorage.removeItem('inviteCode')
  sessionStorage.removeItem('payment-method-required')

  tokensState = {
    authTokens: null,
    guestTokens: null,
  }
}

export function removeGuestTokens() {
  getLocalStorage().removeItem(GUEST_TOKENS_STORAGE_KEY)
  Cookies.remove(GUEST_TOKENS_COOKIE_NAME, cookieAttributes)
}

export function getGuestTokens() {
  const unparsedTokens = Cookies.get(GUEST_TOKENS_COOKIE_NAME)

  if (!unparsedTokens && !tokensState.guestTokens) {
    return false
  }

  try {
    const tokens = unparsedTokens
      ? JSON.parse(unparsedTokens)
      : tokensState.guestTokens

    if (tokens.date < new Date().valueOf() - 518400000) {
      // 6 days old
      return false
    } else {
      return tokens
    }
  } catch (err) {
    return false
  }
}

export function setGuestTokens(tokens: Record<string, string>) {
  const tokensWithDate = {
    ...tokens,
    date: new Date().valueOf(),
  }

  tokensState.guestTokens = tokensWithDate

  Cookies.set(
    GUEST_TOKENS_COOKIE_NAME,
    JSON.stringify(tokensWithDate),
    cookieAttributes
  )
}

export function migrateTokensInStorageToCookies() {
  const idTokenFromStorage = getLocalStorage().getItem(ID_TOKEN_STORAGE_KEY)
  const idTokenCookie = Cookies.get(ID_TOKEN_COOKIE_NAME)
  if (idTokenFromStorage && !idTokenCookie) {
    Cookies.set(ID_TOKEN_COOKIE_NAME, idTokenFromStorage, cookieAttributes)
  }

  const refreshTokenFromStorage = getLocalStorage().getItem(
    REFRESH_TOKEN_STORAGE_KEY
  )
  const refreshTokenCookie = Cookies.get(REFRESH_TOKEN_COOKIE_NAME)
  if (refreshTokenFromStorage && !refreshTokenCookie) {
    Cookies.set(
      REFRESH_TOKEN_COOKIE_NAME,
      refreshTokenFromStorage,
      cookieAttributes
    )
  }

  const accessTokenFromStorage = getLocalStorage().getItem(
    ACCESS_TOKEN_STORAGE_KEY
  )
  const accessTokenCookie = Cookies.get(ACCESS_TOKEN_COOKIE_NAME)
  if (accessTokenFromStorage && !accessTokenCookie) {
    Cookies.set(
      ACCESS_TOKEN_COOKIE_NAME,
      accessTokenFromStorage,
      cookieAttributes
    )
  }

  const guestTokensFromStorage = getLocalStorage().getItem(
    GUEST_TOKENS_STORAGE_KEY
  )
  const guestTokensCookie = Cookies.get(GUEST_TOKENS_COOKIE_NAME)
  if (guestTokensFromStorage && !guestTokensCookie) {
    Cookies.set(
      GUEST_TOKENS_COOKIE_NAME,
      guestTokensFromStorage,
      cookieAttributes
    )
  }
}
