module.exports = {
  fonts: {
    tiny: '7.5pt',
    mini: '9pt',
    small: '10pt',
    medium: '12pt',
    normal: '14pt',
    big: '16pt',
    large: '24pt',
    xLarge: '42pt',
  },
  weights: {
    normal: '400',
    medium: '500',
    bold: '700',
  },
  colors: {
    skyBlue: '#a3e8ff',
    darkBlue: '#357EDD',
    darkenBlue: '#0F256E',
    mayaBlue: '#80c3ff',
    red: '#f44336',
    gray: '#f5f5f5',
    black: '#000',
    cyan: '#3cb4c4',
    gray70: '#4d4d4d',
    darkGray: '#808080',
    lightGray: '#afaeae',
    facebook: '#3B5998',
    twitter: '#55ACEE',
    pinterest: '#CB2027',
    white: '#fff',
    light: '#fcfeff',
    lightBlack: '#1a1a1a',
    darkPink: '#fd0b7b',
    smoke: '#d4d3d3',
    google: '#4285F4',
    lightGreen: '#00E398',
    lightPink: '#ff3399',
    azureRadianceBlue: '#0B7CFF',
  },
  screens: {
    extraWide: '1600px',
    wide: '1400px',
    big: '1230px',
    large: '1024px',
    larger: '1060px',
    medium: '960px',
    mid: '800px',
    small: '680px',
    smaller: '580px',
    tiny: '490px',
  },
}
