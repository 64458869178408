import { TypePolicy } from '@apollo/client'

export const UserType: TypePolicy = {
  fields: {
    state: {
      merge(existing, incoming) {
        if (!incoming) return existing
        return incoming
      },
    },
    userPlan: {
      merge(existing, incoming, { mergeObjects }) {
        if (!incoming) return existing

        return mergeObjects(existing, incoming)
      },
    },
    paymentMethods: {
      merge(existing, incoming) {
        if (!incoming) return existing
        if (!existing) return incoming
        const edgeNodeIdLookup: Record<string, { __ref: string }> = {}
        const allEdges = [...existing.edges, ...incoming.edges]
        for (const edge of allEdges) {
          edgeNodeIdLookup[edge.node.__ref] = edge
        }

        return {
          ...existing,
          edges: Object.values(edgeNodeIdLookup),
        }
      },
    },
  },
}
