import * as Sentry from '@sentry/gatsby'
import { IMutableContext, useUnleashContext } from '@unleash/proxy-client-react'
import Cookies, { CookieAttributes } from 'js-cookie'
import React, { useContext, useEffect, useRef, useState } from 'react'

import { PAWP_DOMAIN } from 'constants/cookies'
import UserContext from 'context/UserContext'
import {
  MutationRequestGuestSessionVars,
  useRequestGuestSession,
} from 'graphql/auth/mutations/requestGuestSession'
import { migrateTokensInStorageToCookies } from 'services/user'
import { debounceFullstoryIdentify } from 'utils/debounceFullstoryIdentify'
import { isDevelopment } from 'utils/env'
import { getAttributionSource } from 'utils/getCheckoutAttributionInput'
import { createURLParams, parseURLParams } from 'utils/url'
import getInitialInteraction from './getInitialInteraction'
import { useUserManagementData } from './queries/userManagementData'
import { SegmentTraits } from './SegmentTraits'
import { useDynamicInitialInteractionUrls } from './useDynamicInitialInteractionUrls'

const COOKIE_ATTRIBUTES: CookieAttributes =
  typeof window !== 'undefined'
    ? {
        ...(isDevelopment() ? {} : { domain: PAWP_DOMAIN }),
        secure: window.location.protocol === 'https:',
        sameSite: 'strict',
        expires: 30,
      }
    : {}

interface SentryUser {
  email?: string
  id?: string
  username?: string
}

export default function UserManagement(): JSX.Element {
  const dynamicInitialInteractions = useDynamicInitialInteractionUrls()

  const updateContext = useUnleashContext()
  const { isAuth, isGuest, authenticateGuest } = useContext(UserContext)
  const { data: userData, refetch: refetchUser } = useUserManagementData({
    skip: !isAuth && !isGuest,
    fetchPolicy: 'network-only',
  })
  const user = userData?.user
  const [requestGuestSession] = useRequestGuestSession()
  const [identifiedId, setIdentifiedId] = useState('')

  // segment user tracking identification
  useEffect(() => {
    const username = user?.email || user?.phoneNumber
    if (user?.id && `${user.id}-${username}` !== identifiedId) {
      const traits: SegmentTraits = {}
      let segmentOptions: SegmentAnalytics.SegmentOpts | undefined = {
        integrations: { 'Customer.io': false, Intercom: false },
        context: { userHasPlan: false },
      }
      window.isIdentified = false
      window.userHasPlan = false

      if (username) {
        window.isIdentified = true
        delete segmentOptions.integrations['Intercom']
      }
      if (user.userPlan) {
        window.userHasPlan = true
        segmentOptions.context = { userHasPlan: true }
        delete segmentOptions.integrations['Customer.io']
      }
      if (user.email) {
        traits.email = user.email
      }

      if (user.lastIosActivityAt) {
        // Disable ad-based tracking for iOS users
        segmentOptions = {
          ...segmentOptions,
          integrations: {
            ...segmentOptions?.integrations,
            'Google Ads (Gtag)': false,
            'Pinterest Tag': false,
            'Facebook Conversions API': false,
          },
        }
      }

      if (user.externalAccessTokens?.intercomWeb) {
        traits.user_hash = user.externalAccessTokens.intercomWeb
      }

      if (process.env.NODE_ENV === 'development') {
        console.info('identify', user.id, traits, segmentOptions)
      }

      if (window.analytics && isAuth) {
        window.analytics.identify(user.id, traits, segmentOptions)

        debounceFullstoryIdentify(() => {
          if (window.FS) {
            window.FS.identify(user.id, traits)
          }
        })
      }

      const context: IMutableContext = {
        userId: user.id,
      }

      if (user.partner?.slug) {
        context.properties = { partnerSlug: user.partner.slug }
      }

      updateContext(context)
      setIdentifiedId(`${user.id}-${username}`)
    }
  }, [
    identifiedId,
    updateContext,
    user?.email,
    user?.externalAccessTokens?.intercomWeb,
    user?.id,
    user?.lastIosActivityAt,
    user?.partner?.slug,
    user?.phoneNumber,
    user?.userPlan,
    isAuth,
  ])

  // Guest user management
  useEffect(() => {
    const {
      identifier = '',
      guest_token: guestToken = '',
      ...rest
    } = parseURLParams(window.location.search)

    async function getGuestAuthentication() {
      try {
        const interaction = getInitialInteraction(
          window.location.pathname,
          dynamicInitialInteractions
        )
        Cookies.set(
          'persistedInitialInteraction',
          interaction,
          COOKIE_ATTRIBUTES
        )

        const variables: MutationRequestGuestSessionVars = {
          interaction,
        }

        const source = getAttributionSource()
        if (source) {
          variables.source = source
        }

        const { data } = await requestGuestSession({ variables })

        if (data?.requestGuestSession) {
          const session = data?.requestGuestSession

          if (session.token && session.identifier) {
            authenticateGuest(true, {
              token: session.token,
              identifier: session.identifier,
            })
          }
        }
      } catch (err) {
        console.log('failed to get guest authentication')
      }
    }

    if (identifier && guestToken) {
      window.history.replaceState(
        undefined,
        '',
        `${window.location.pathname}${createURLParams(rest)}`
      )
      authenticateGuest(true, {
        token: guestToken,
        identifier: identifier,
      })
      setTimeout(() => {
        refetchUser()
      }, 500)
    } else if (!isAuth && !isGuest) {
      getGuestAuthentication()
    }
  }, [
    authenticateGuest,
    dynamicInitialInteractions,
    isAuth,
    isGuest,
    refetchUser,
    requestGuestSession,
  ])

  // Appsflyer identification
  const appsFlyerIdentifiedRef = useRef(false)
  const retryCountRef = useRef(0)
  useEffect(() => {
    if (appsFlyerIdentifiedRef.current || !user?.id || !isAuth) return

    // Check every second to see if the script has loaded, once it has identify
    const interval = setInterval(() => {
      if (window.AF) {
        window.AF('pba', 'setCustomerUserId', user.id)
        clearInterval(interval)
        appsFlyerIdentifiedRef.current = true
      }

      if (retryCountRef.current >= 10) {
        clearInterval(interval)
      }

      retryCountRef.current++
    }, 1000)

    return () => {
      if (interval) {
        clearInterval(interval)
      }
    }
  }, [isAuth, user?.id])

  // Sentry identification
  useEffect(() => {
    if (user?.id) {
      const sentryUser: SentryUser = {}

      if (user?.id) {
        sentryUser.id = user.id
      }

      if (user?.email) {
        sentryUser.email = user.email
        sentryUser.username = user.email
      }

      if (user?.phoneNumber && !sentryUser.username) {
        sentryUser.username = user.phoneNumber
      }

      Sentry.setUser(sentryUser)
    }

    return () => Sentry.setUser(null)
  }, [user?.email, user?.id, user?.phoneNumber])

  // Customer.io identification for sending in-app messages
  useEffect(() => {
    if (user?.id && user?.userPlan) {
      if (window._cio) {
        window._cio.identify({
          id: user.id,
        })
      }
    }
  }, [user?.id, user?.userPlan])

  useEffect(() => {
    migrateTokensInStorageToCookies()
  }, [])

  return <></>
}
