import { Reference, TypePolicy } from '@apollo/client'
import { uniqueItems } from 'utils/list'

export const ConversationType: TypePolicy = {
  fields: {
    carePlans: {
      merge(existing, incoming) {
        if (!incoming) return existing
        if (!existing) return incoming
        if (incoming.length && incoming.length < existing.length) {
          return incoming
        }
        return uniqueItems([...incoming, ...existing], '__ref')
      },
    },
    messages: {
      // Don't cache separate results based on the field's arguments
      keyArgs: false,
      read(value, { readField }) {
        if (!value) return value

        const messages: Reference[] = value.messages.slice(0)
        const sortMessageRefs = (a: Reference, b: Reference) => {
          const messageAIndex = readField('twilioIndex', a) || 0
          const messageBIndex = readField('twilioIndex', b) || 0

          return messageAIndex > messageBIndex ? 1 : -1
        }
        const sortedMessages = messages.sort(sortMessageRefs)
        return {
          ...value,
          messages: sortedMessages,
        }
      },
      merge(existing, incoming) {
        if (!incoming) return existing
        if (!existing) return incoming
        const next = { ...existing, ...incoming }
        if (incoming.messages) {
          const allMessages = [...existing.messages, ...incoming.messages]
          next.messages = uniqueItems(allMessages, '__ref')
        }
        if (incoming.summaries) {
          const allSummaries = [...existing.summaries, ...incoming.summaries]
          next.summaries = uniqueItems(allSummaries, '__ref')
        }

        return next
      },
    },
  },
}
