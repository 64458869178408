/* tslint:disable */
/* eslint-disable */
// @generated
// This file was automatically generated and should not be edited.

//==============================================================
// START Enums and Input Objects
//==============================================================
import { PaymentSource } from '_generated/gql/graphql'

import { Period } from '_generated/gql/graphql'

export { Period }

export enum ActionStatus {
  ASSIGNED = 'ASSIGNED',
  CANCELLED = 'CANCELLED',
  COMPLETED = 'COMPLETED',
  EXPIRED = 'EXPIRED',
  PENDING = 'PENDING',
  STARTED = 'STARTED',
}

export enum AddressState {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  FM = 'FM',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MH = 'MH',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  PW = 'PW',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
}

export enum AuthFlow {
  LOGIN = 'LOGIN',
  SIGNUP = 'SIGNUP',
  SOFT_SIGNUP = 'SOFT_SIGNUP',
}

export enum AuthMethod {
  OTP = 'OTP',
  PASSWORD = 'PASSWORD',
}

export enum BookingStatus {
  CANCELED = 'CANCELED',
  COMPLETED = 'COMPLETED',
  CONFIRMED = 'CONFIRMED',
  IN_PROGRESS = 'IN_PROGRESS',
  PENDING = 'PENDING',
}

export enum CarePlanStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
  REPLACED = 'REPLACED',
}

export enum ConversationRequestLevel {
  EMERGENCY = 'EMERGENCY',
  STANDARD = 'STANDARD',
}

export enum ConversationResolutionType {
  CONTACT_PAWP_SUPPORT = 'CONTACT_PAWP_SUPPORT',
  CALL_RDVM = 'CALL_RDVM',
  DEEMED_EMERGENCY_MEMBER_ELECTS_TO_MONITOR_AMA = 'DEEMED_EMERGENCY_MEMBER_ELECTS_TO_MONITOR_AMA',
  DEEMED_EMERGENCY_MEMBER_TO_SEEK_CARE = 'DEEMED_EMERGENCY_MEMBER_TO_SEEK_CARE',
  DEEMED_POSSIBLE_EMERGENCY_MEMBER_TO_MONITOR_AND_FOLLOW_UP = 'DEEMED_POSSIBLE_EMERGENCY_MEMBER_TO_MONITOR_AND_FOLLOW_UP',
  DEEMED_POSSIBLE_EMERGENCY_MEMBER_TO_SEEK_CARE = 'DEEMED_POSSIBLE_EMERGENCY_MEMBER_TO_SEEK_CARE',
  DISCONNECTED = 'DISCONNECTED',
  EMERGENCY = 'EMERGENCY',
  EMERGENCY_FUND_ACTIVATED = 'EMERGENCY_FUND_ACTIVATED',
  KEEP_RDVM_APPT = 'KEEP_RDVM_APPT',
  MONITOR_AT_HOME = 'MONITOR_AT_HOME',
  NEVER_CONNECTED_TO_VIDEO = 'NEVER_CONNECTED_TO_VIDEO',
  NEVER_RESPONDED_TO_CHAT = 'NEVER_RESPONDED_TO_CHAT',
  NO_FOLLOW_UP = 'NO_FOLLOW_UP',
  OTHER = 'OTHER',
  SCHEDULE_APPT_IN_24_HRS = 'SCHEDULE_APPT_IN_24_HRS',
  SCHEDULE_APPT_IN_3_DAYS = 'SCHEDULE_APPT_IN_3_DAYS',
  SCHEDULE_APPT_IN_7_DAYS = 'SCHEDULE_APPT_IN_7_DAYS',
  SCHEDULE_EARLIEST_RDVM_APPT = 'SCHEDULE_EARLIEST_RDVM_APPT',
  STOPPED_RESPONDED_TO_CHAT = 'STOPPED_RESPONDED_TO_CHAT',
  USER_ALREADY_AT_CLINIC = 'USER_ALREADY_AT_CLINIC',
  WILL_REACH_BACK_OUT = 'WILL_REACH_BACK_OUT',
}

export enum ConversationSummaryStatus {
  DRAFT = 'DRAFT',
  PUBLISHED = 'PUBLISHED',
}

export enum DeviceNotificationPermission {
  DENIED = 'DENIED',
  EPHEMERAL = 'EPHEMERAL',
  GRANTED = 'GRANTED',
  NOT_DETERMINED = 'NOT_DETERMINED',
  PROVISIONAL = 'PROVISIONAL',
}

export enum DeviceType {
  ANDROID = 'ANDROID',
  IOS = 'IOS',
  WEB = 'WEB',
}

export enum EmergencyReason {
  ABNORMAL_SEIZING = 'ABNORMAL_SEIZING',
  CHOKING_OR_DISTRESSED_BREATHING = 'CHOKING_OR_DISTRESSED_BREATHING',
  CHRONIC_ILLNESS_CRISIS_POINT = 'CHRONIC_ILLNESS_CRISIS_POINT',
  EYE_INJURIES = 'EYE_INJURIES',
  INABILITY_TO_DEFECATE_OR_BLOCKAGE = 'INABILITY_TO_DEFECATE_OR_BLOCKAGE',
  LABORED_BREATHING = 'LABORED_BREATHING',
  LOSS_OF_CONSCIOUSNESS = 'LOSS_OF_CONSCIOUSNESS',
  MELENA_OR_HEMATEMESIS = 'MELENA_OR_HEMATEMESIS',
  MODERATE_DIARRHEA = 'MODERATE_DIARRHEA',
  MODERATE_VOMITING = 'MODERATE_VOMITING',
  PALE_BLUE_OR_GREY_GUMS = 'PALE_BLUE_OR_GREY_GUMS',
  PARALYSIS_OR_PARESIS = 'PARALYSIS_OR_PARESIS',
  PYOMETRA = 'PYOMETRA',
  RAPIDLY_DETERIORATING_STATUS = 'RAPIDLY_DETERIORATING_STATUS',
  RELENTLESS_VOCALIZATIONS_AND_DISTRESS = 'RELENTLESS_VOCALIZATIONS_AND_DISTRESS',
  SEEN_OR_SUSPECTED_TRAUMA = 'SEEN_OR_SUSPECTED_TRAUMA',
  SEIZURES = 'SEIZURES',
  SEVERE_DEHYDRATION = 'SEVERE_DEHYDRATION',
  SEVERE_DIARRHEA = 'SEVERE_DIARRHEA',
  SEVERE_DISTRESS = 'SEVERE_DISTRESS',
  SEVERE_EXTERNAL_BLEEDING = 'SEVERE_EXTERNAL_BLEEDING',
  SEVERE_INJURY_TO_EYES = 'SEVERE_INJURY_TO_EYES',
  SEVERE_OR_NON_STOP_VOMITING_DIARRHEA = 'SEVERE_OR_NON_STOP_VOMITING_DIARRHEA',
  SEVERE_TRAUMA = 'SEVERE_TRAUMA',
  SEVERE_VOMITING = 'SEVERE_VOMITING',
  SUSPECTED_INTERNAL_BLEEDING = 'SUSPECTED_INTERNAL_BLEEDING',
  TEMPERATURE_OVER_105F = 'TEMPERATURE_OVER_105F',
  TOXIC_INGESTION = 'TOXIC_INGESTION',
  UNCONSCIOUSNESS_OR_SUDDEN_COLLAPSE = 'UNCONSCIOUSNESS_OR_SUDDEN_COLLAPSE',
  UNPRODUCTIVE_RETCHING_OR_DRY_HEAVES = 'UNPRODUCTIVE_RETCHING_OR_DRY_HEAVES',
  UNPRODUCTIVE_STRAINING_TO_URINATE_OR_DEFECATE = 'UNPRODUCTIVE_STRAINING_TO_URINATE_OR_DEFECATE',
}

export enum EmergencyRequestGumColor {
  BLUE = 'BLUE',
  PINK = 'PINK',
  UNAVAILABLE = 'UNAVAILABLE',
  WHITE = 'WHITE',
}

export enum EmergencyRequestIssueLength {
  MORE_THAN_WEEK = 'MORE_THAN_WEEK',
  MULTIPLE_DAYS = 'MULTIPLE_DAYS',
  ONE_DAY = 'ONE_DAY',
}

export enum FulfillmentShipmentStatus {
  ATTEMPTED_DELIVERY = 'ATTEMPTED_DELIVERY',
  CONFIRMED = 'CONFIRMED',
  DELIVERED = 'DELIVERED',
  FAILURE = 'FAILURE',
  IN_TRANSIT = 'IN_TRANSIT',
  LABEL_PRINTED = 'LABEL_PRINTED',
  LABEL_PURCHASED = 'LABEL_PURCHASED',
  OUT_FOR_DELIVERY = 'OUT_FOR_DELIVERY',
  READY_FOR_PICKUP = 'READY_FOR_PICKUP',
}

export enum FulfillmentTrackingCompany {
  AMAZON_LOGISTICS_UK = 'AMAZON_LOGISTICS_UK',
  AMAZON_LOGISTICS_US = 'AMAZON_LOGISTICS_US',
  ANJUN_LOGISTICS = 'ANJUN_LOGISTICS',
  APC = 'APC',
  AUSTRALIA_POST = 'AUSTRALIA_POST',
  A_4PX = 'A_4PX',
  BLUEDART = 'BLUEDART',
  CANADA_POST = 'CANADA_POST',
  CANPAR = 'CANPAR',
  CHINA_POST = 'CHINA_POST',
  CHUKOU1 = 'CHUKOU1',
  CORREIOS = 'CORREIOS',
  COURIERS_PLEASE = 'COURIERS_PLEASE',
  DELHIVERY = 'DELHIVERY',
  DHL_ECOMMERCE = 'DHL_ECOMMERCE',
  DHL_ECOMMERCE_ASIA = 'DHL_ECOMMERCE_ASIA',
  DHL_EXPRESS = 'DHL_EXPRESS',
  DPD = 'DPD',
  DPD_LOCAL = 'DPD_LOCAL',
  DPD_UK = 'DPD_UK',
  EAGLE = 'EAGLE',
  FEDEX = 'FEDEX',
  FSC = 'FSC',
  GLOBEGISTICS = 'GLOBEGISTICS',
  GLS = 'GLS',
  GLS_US_ = 'GLS_US_',
  JAPAN_POST = 'JAPAN_POST',
  LA_POSTE = 'LA_POSTE',
  NEWGISTICS = 'NEWGISTICS',
  NEW_ZEALAND_POST = 'NEW_ZEALAND_POST',
  OTHER = 'OTHER',
  POSTNL = 'POSTNL',
  POSTNORD = 'POSTNORD',
  PUROLATOR = 'PUROLATOR',
  ROYAL_MAIL = 'ROYAL_MAIL',
  SAGAWA = 'SAGAWA',
  SENDLE = 'SENDLE',
  SFC_FULFILLLMENT = 'SFC_FULFILLLMENT',
  SF_EXPRESS = 'SF_EXPRESS',
  SINGAPORE_POST = 'SINGAPORE_POST',
  STARTRACK = 'STARTRACK',
  TNT = 'TNT',
  TOLL_IPEC = 'TOLL_IPEC',
  UPS = 'UPS',
  USPS = 'USPS',
  WHISTL = 'WHISTL',
  YAMATO = 'YAMATO',
  YUNEXPRESS = 'YUNEXPRESS',
}

export enum OrderCancelReason {
  CUSTOMER = 'CUSTOMER',
  DECLINED = 'DECLINED',
  FRAUD = 'FRAUD',
  INVENTORY = 'INVENTORY',
  OTHER = 'OTHER',
  PRESCRIPTION_REJECTED = 'PRESCRIPTION_REJECTED',
}

export enum PetAppetite {
  LESS_THAN_NORMAL = 'LESS_THAN_NORMAL',
  MORE_THAN_NORMAL = 'MORE_THAN_NORMAL',
  NONE_MORE_THAN_24_HOURS = 'NONE_MORE_THAN_24_HOURS',
  NORMAL = 'NORMAL',
}

export enum PetBehavior {
  ANXIOUS = 'ANXIOUS',
  LETHARGIC = 'LETHARGIC',
  NORMAL = 'NORMAL',
}

export enum PetWaterConsumption {
  LESS_THAN_NORMAL = 'LESS_THAN_NORMAL',
  MORE_THAN_NORMAL = 'MORE_THAN_NORMAL',
  NONE_MORE_THAN_24_HOURS = 'NONE_MORE_THAN_24_HOURS',
  NORMAL = 'NORMAL',
}

export enum PlanStatus {
  ACTIVE = 'ACTIVE',
  CANCELED = 'CANCELED',
  DUNNING = 'DUNNING',
  EXPIRED = 'EXPIRED',
  REPLACED = 'REPLACED',
  TRIAL = 'TRIAL',
}

export enum PreparedResponseActionType {
  DELETE = 'DELETE',
  PUBLISH = 'PUBLISH',
  UNPUBLISH = 'UNPUBLISH',
}

export enum PrescriptionStatus {
  APPROVED = 'APPROVED',
  CANCELLED = 'CANCELLED',
  PENDING_APPROVAL = 'PENDING_APPROVAL',
  REJECTED = 'REJECTED',
  REQUESTED = 'REQUESTED',
}

export enum PriceTypeEnum {
  ONE_TIME = 'ONE_TIME',
  RECURRING = 'RECURRING',
}

export enum ProductVendorType {
  FIRST_PARTY = 'FIRST_PARTY',
  THIRD_PARTY = 'THIRD_PARTY',
}

export enum PublishStatus {
  ACTIVE = 'ACTIVE',
  ARCHIVED = 'ARCHIVED',
  DRAFT = 'DRAFT',
}

export enum QuantityType {
  CHEW = 'CHEW',
  GRAMS = 'GRAMS',
  ITEM = 'ITEM',
  MILLIGRAMS = 'MILLIGRAMS',
  PILL = 'PILL',
  TABLET = 'TABLET',
}

export enum QuestionType {
  ACCOUNT = 'ACCOUNT',
  ADDRESS = 'ADDRESS',
  CHECKBOX = 'CHECKBOX',
  CHOICE = 'CHOICE',
  DATETIME = 'DATETIME',
  DATETIME_RANGE = 'DATETIME_RANGE',
  DECIMAL = 'DECIMAL',
  DECIMAL_RANGE = 'DECIMAL_RANGE',
  INTEGER = 'INTEGER',
  INTEGER_RANGE = 'INTEGER_RANGE',
  PAYMENT_METHOD = 'PAYMENT_METHOD',
  PET = 'PET',
  TEXT = 'TEXT',
}

export enum SharedArticleExternalService {
  CONTENTFUL = 'CONTENTFUL',
}

export enum ShippingAddressState {
  AK = 'AK',
  AL = 'AL',
  AR = 'AR',
  AS = 'AS',
  AZ = 'AZ',
  CA = 'CA',
  CO = 'CO',
  CT = 'CT',
  DC = 'DC',
  DE = 'DE',
  FL = 'FL',
  FM = 'FM',
  GA = 'GA',
  GU = 'GU',
  HI = 'HI',
  IA = 'IA',
  ID = 'ID',
  IL = 'IL',
  IN = 'IN',
  KS = 'KS',
  KY = 'KY',
  LA = 'LA',
  MA = 'MA',
  MD = 'MD',
  ME = 'ME',
  MH = 'MH',
  MI = 'MI',
  MN = 'MN',
  MO = 'MO',
  MP = 'MP',
  MS = 'MS',
  MT = 'MT',
  NC = 'NC',
  ND = 'ND',
  NE = 'NE',
  NH = 'NH',
  NJ = 'NJ',
  NM = 'NM',
  NV = 'NV',
  NY = 'NY',
  OH = 'OH',
  OK = 'OK',
  OR = 'OR',
  PA = 'PA',
  PR = 'PR',
  PW = 'PW',
  RI = 'RI',
  SC = 'SC',
  SD = 'SD',
  TN = 'TN',
  TX = 'TX',
  UT = 'UT',
  VA = 'VA',
  VI = 'VI',
  VT = 'VT',
  WA = 'WA',
  WI = 'WI',
  WV = 'WV',
  WY = 'WY',
}

export enum SubscriptionStatus {
  ACTIVE = 'ACTIVE',
  CANCELLED = 'CANCELLED',
  EXPIRED = 'EXPIRED',
  INCOMPLETE = 'INCOMPLETE',
  PAST_DUE = 'PAST_DUE',
  TRIAL = 'TRIAL',
  UNPAID = 'UNPAID',
}

export enum SymptomSeverityLevel {
  HIGH = 'HIGH',
  IMMEDIATE = 'IMMEDIATE',
  LOW = 'LOW',
  MEDIUM = 'MEDIUM',
  MONITOR = 'MONITOR',
  NONE = 'NONE',
}

export enum UserFlagType {
  AbuseOfUnlimitedConvos = 'ABUSE_OF_UNLIMITED_CONVOS',
  AbusiveLanguage = 'ABUSIVE_LANGUAGE',
  Banned = 'BANNED',
  Fraud = 'FRAUD',
  SuspectedMedicalNegligenceOrFraud = 'SUSPECTED_MEDICAL_NEGLIGENCE_OR_FRAUD',
}

export interface ActivateAddOnInput {
  addOn: PlanAddOnInput
  attribution?: AttributionInput | null
  paymentMethod?: string | null
  clientMutationId?: string | null
}

export interface AddCarePlanInput {
  carePlan: CreateCarePlanInput
  clientMutationId?: string | null
}

export interface AddGenericCarePlanActionInput {
  carePlan?: string | null
  actionType: CoreSlug
  sortingWeight?: number | null
  period?: Period | null
  interval?: number | null
  alternateDetails?: string | null
  autocompleteDate?: CoreDateTime | null
  asDraft?: boolean | null
  notes?: string | null
  clientMutationId?: string | null
  conversation?: string | null
}

export interface AddPaymentMethodInput {
  paymentMethod: CreateUserPaymentMethodInput
  applyToUserPlan?: boolean | null
  clientMutationId?: string | null
}

export interface AddRecommendationCarePlanActionInput {
  carePlan?: string | null
  sortingWeight?: number | null
  product?: string | null
  productGuidance?: string | null
  externalProductTitle?: string | null
  externalUrls?: (string | null)[] | null
  asDraft?: boolean | null
  clientMutationId?: string | null
  conversation?: string | null
}

export interface AddRelatedResourceCarePlanActionInput {
  carePlan?: string | null
  sortingWeight?: number | null
  externalId: string
  name?: string | null
  asDraft?: boolean | null
  notes?: string | null
  clientMutationId?: string | null
  conversation?: string | null
}

export interface AddScheduleAppointmentCarePlanActionInput {
  carePlan?: string | null
  sortingWeight?: number | null
  providerType: string
  scheduleByDeadline?: CoreDateTime | null
  asDraft?: boolean | null
  notes?: string | null
  clientMutationId?: string | null
  conversation?: string | null
}

export interface AddUserFlagInput {
  userFlag: CreateUserFlagInput
  clientMutationId?: string | null
}

export interface AnswerInput {
  questionSlug: string
  type: QuestionType
  choice?: (string | null)[] | null
  datetime?: CoreDateTime | null
  decimal?: CoreDecimal | null
  int?: number | null
  isChecked?: boolean | null
  text?: string | null
  datetimeLower?: CoreDateTime | null
  decimalLower?: CoreDecimal | null
  intLower?: number | null
  datetimeUpper?: CoreDateTime | null
  decimalUpper?: CoreDecimal | null
  intUpper?: number | null
  instanceId?: string | null
}

export interface AttributionInput {
  channel: string
  clickId?: string | null
  meta?: CoreJSONString | null
}

export interface BillingCycleInput {
  anchorDate: CoreDateTime
}

export interface BirthdayInput {
  birthday: string
  accuracyLevel: string
}

export interface CancelAutoshipPlanInput {
  autoshipPlanId: string
  clientMutationId?: string | null
}

export interface CarePlanSharedArticleInput {
  externalId: string
  name?: string | null
}

export interface CompleteActionInput {
  action: string
  cancel?: boolean | null
  clientMutationId?: string | null
}

export interface ConnectUserToPartnerInput {
  partnerSlug: CoreSlug
  clientMutationId?: string | null
}

export interface ConversationSummaryInput {
  conversation: string
  pet: string
  status?: ConversationSummaryStatus | null
  recap?: string | null
  descriptionOfIssues?: (string | null)[] | null
}

export interface CreateCarePlanInput {
  pet: string
  conversation: string
}

export interface CreateEntityInput {
  name: string
  size: string
  url: string
  billingEmail: string
  entityType: string
  plan?: string | null
  domains?: (string | null)[] | null
  logo?: CoreImageUpload | null
  clientMutationId?: string | null
}

export interface CreateUserFlagInput {
  flagType: UserFlagType
  notes?: string | null
  owner: string
  conversation: string
}

export interface CreateUserPaymentMethodInput {
  nickname?: string | null
  source: PaymentSource
  token?: string | null
  processorId?: string | null
}

export interface DeleteDraftActionInput {
  action: string
  clientMutationId?: string | null
}

export interface DeviceInput {
  deviceIdentifier?: string | null
  deviceType: DeviceType
  notificationPermission?: DeviceNotificationPermission | null
  deviceName?: string | null
  osVersion?: string | null
  applicationVersion?: string | null
  timezone?: string | null
  locale?: string | null
  isAdTrackingEnabled?: boolean | null
  advertisingId?: string | null
  appsFlyerId?: string | null
  trackingStatus?: string | null
  externalIds?: CoreJSONString | null
  pushToken?: string | null
}

export interface DuplicateCarePlanInput {
  previousCarePlan: string
  conversation: string
  clientMutationId?: string | null
}

export interface EmergencyRequestQuestionnaireInput {
  isPresent: boolean
  isEatingDrinking: boolean
  hasSeenVet: boolean
  issueLength: EmergencyRequestIssueLength
  colorOfGums: EmergencyRequestGumColor
}

export interface GiftRecipientInput {
  email: string
  firstName: string
  lastName: string
}

export interface GuestGiftPurchaserInput {
  email: string
  firstName?: string | null
  lastName?: string | null
}

export interface GuestSessionInput {
  identifier: string
  token: string
}

export interface InsertPrescriptionInput {
  cartLineId: string
  clinicId: string
  petId: string
}

export interface InsertPrescriptionNodeInput {
  prescription: InsertPrescriptionInput
  clientMutationId?: string | null
}

export interface LocationInput {
  address1?: string | null
  address2?: string | null
  city?: string | null
  state?: string | null
  zip?: string | null
}

export interface LoginV2Input {
  username: string
  password: string
  guestSession?: GuestSessionInput | null
  clientMutationId?: string | null
}

export interface MicrochipInput {
  id: string
  brand?: string | null
}

export interface ModifyCarePlanInput {
  carePlan: string
  status?: CarePlanStatus | null
  actionsToCancel?: (string | null)[] | null
}

export interface NextInput {
  formSlug: string
  answers?: (AnswerInput | null)[] | null
  questionGroupSlug?: string | null
  clientMutationId?: string | null
}

export interface PaymentMethodInput {
  token: string
}

export interface PlanAddOnInput {
  slug: CoreSlug
  petId?: number | null
  interval?: number | null
  period?: Period | null
}

export interface PlanQuoteInput {
  plan: string
  interval?: number | null
  period?: Period | null
  startInTrial?: boolean | null
  configuration?: string | null
}

export interface PreviousInput {
  formSlug: string
  questionGroupSlug: string
  answers?: (AnswerInput | null)[] | null
  clientMutationId?: string | null
}

export interface PurchaseMembershipInput {
  giftMembershipId: string
  giftMembershipTemplateId: string
  recipient: GiftRecipientInput
  purchasedByGuest?: GuestGiftPurchaserInput | null
  giftMessage?: string | null
  deliverAt?: CoreDateTime | null
  existingPaymentMethodId?: string | null
  newPaymentMethod?: CreateUserPaymentMethodInput | null
}

export interface QuoteInput {
  plan?: string | null
  membership?: PlanQuoteInput | null
  promoCode?: string | null
  giftCode?: string | null
  shippingAddress?: string | null
  addOns?: (PlanAddOnInput | null)[] | null
}

export interface RabiesInput {
  tagNumber: string
  brand?: string | null
}

export interface RegisterDeviceInput {
  device: DeviceInput
  clientMutationId?: string | null
}

export interface RejectConversationInput {
  conversation: string
  clientMutationId?: string | null
}

export interface RemoveAddOnInput {
  addOnStatus: string
  clientMutationId?: string | null
}

export interface SelectPlanInput {
  plan: CoreSlug
  interval?: number | null
  period?: Period | null
  promoCode?: string | null
  newPaymentMethod?: CreateUserPaymentMethodInput | null
  startInTrial?: boolean | null
  addOns?: (PlanAddOnInput | null)[] | null
  configuration?: string | null
}

export interface SendOTPInput {
  username: string
  clientMutationId?: string | null
}

export interface SetUserPlanInput {
  giftCode?: string | null
  attribution?: AttributionInput | null
  plan?: SelectPlanInput | null
  updateUser?: UpdateUserInput | null
  newPaymentMethod?: CreateUserPaymentMethodInput | null
  clientMutationId?: string | null
}

export interface SignupV2Input {
  username: string
  password: string
  attribution?: AttributionInput | null
  clientMutationId?: string | null
}

export interface StartBookingConversationInput {
  bookingId: string
  requesterVideoAspectRatio?: string | null
  platform?: string | null
  clientMutationId?: string | null
}

export interface UpdateAutoshipPlanInput {
  autoshipPlanId: string
  billingCycle?: BillingCycleInput | null
  paymentMethodId?: string | null
  clientMutationId?: string | null
}

export interface UpdateCarePlanInput {
  carePlan: ModifyCarePlanInput
  clientMutationId?: string | null
}

export interface UpdateCartLineItemInput {
  productVariantId: string
  quantity: number
  interval?: number | null
  period?: Period | null
}

export interface UpdateEmailInput {
  newEmail: string
  clientMutationId?: string | null
}

export interface UpdateEntityInput {
  name?: string | null
  size?: string | null
  url?: string | null
  domains?: (string | null)[] | null
  billingEmail?: string | null
  logo?: CoreImageUpload | null
  location?: LocationInput | null
  clientMutationId?: string | null
}

export interface UpdateGenericCarePlanActionInput {
  carePlanAction: string
  status?: ActionStatus | null
  actionType?: CoreSlug | null
  sortingWeight?: number | null
  period?: Period | null
  interval?: number | null
  alternateDetails?: string | null
  autocompleteDate?: CoreDateTime | null
  asDraft?: boolean | null
  notes?: string | null
  clientMutationId?: string | null
}

export interface UpdatePetProfessionalInput {
  conversation: string
  gender?: string | null
  isSpayedOrNeutered?: boolean | null
  pet: string
}

export interface UpdatePetProfessionalNodeInput {
  pet: UpdatePetProfessionalInput
  clientMutationId?: string | null
}

export interface UpdateRecommendationCarePlanActionInput {
  carePlanAction: string
  sortingWeight?: number | null
  status?: ActionStatus | null
  product?: string | null
  productGuidance?: string | null
  externalProductTitle?: string | null
  externalUrls?: (string | null)[] | null
  asDraft?: boolean | null
  clientMutationId?: string | null
}

export interface UpdateRelatedResourceCarePlanActionInput {
  carePlanAction: string
  status?: ActionStatus | null
  sortingWeight?: number | null
  externalId?: string | null
  name?: string | null
  asDraft?: boolean | null
  notes?: string | null
  clientMutationId?: string | null
}

export interface UpdateScheduleAppointmentCarePlanActionInput {
  carePlanAction: string
  status?: ActionStatus | null
  sortingWeight?: number | null
  providerType?: string | null
  scheduleByDeadline?: CoreDateTime | null
  asDraft?: boolean | null
  notes?: string | null
  clientMutationId?: string | null
}

export interface UpdateUserInput {
  email?: string | null
  firstName?: string | null
  lastName?: string | null
  password?: string | null
}

export interface UpsertUserNoteInput {
  userNote: UserNoteUpsertionInput
  clientMutationId?: string | null
}

export interface UserNoteUpsertionInput {
  conversation: string
  contents?: string | null
  publicationStatus?: PublishStatus | null
}

export interface VerifyOtpInput {
  code: string
  identifier: string
  clientMutationId?: string | null
}

//==============================================================
// END Enums and Input Objects
//==============================================================
