import { createQueryHook } from 'graphql/util/createQueryHook'
import { graphql } from '_generated/gql'

const QUERY_USER_MANAGEMENT_DATA = graphql(`
  query UserManagementData {
    user {
      id
      email
      phoneNumber
      lastIosActivityAt
      firstName
      lastName
      externalAccessTokens {
        intercomWeb
      }
      partner {
        id
        slug
      }
      userPlan {
        id
      }
    }
  }
`)

export const useUserManagementData = createQueryHook(QUERY_USER_MANAGEMENT_DATA)
