import { MutationHookOptions, MutationTuple, useMutation } from '@apollo/client'
import gql from 'graphql-tag'

export const MUTATION_REQUEST_GUEST_SESSION = gql`
  mutation RequestGuestSession($interaction: String, $source: String) {
    requestGuestSession(interaction: $interaction, source: $source) {
      identifier
      token
    }
  }
`

export interface MutationRequestGuestSessionData {
  requestGuestSession: {
    token: string
    identifier: string
  }
}
export interface MutationRequestGuestSessionVars {
  interaction?: string
  source?: string
}

export const useRequestGuestSession = (
  options?: MutationHookOptions<
    MutationRequestGuestSessionData,
    MutationRequestGuestSessionVars
  >
): MutationTuple<
  MutationRequestGuestSessionData,
  MutationRequestGuestSessionVars
> => useMutation(MUTATION_REQUEST_GUEST_SESSION, options)
