import { useMemo } from 'react'
import { graphql, useStaticQuery } from 'gatsby'

const QUERY = graphql`
  query DynamicInitialInteractionUrls {
    allContentfulArticle {
      nodes {
        slug
      }
    }
    allContentfulBlogCategoryFeed {
      nodes {
        slug
      }
    }
    allStrapiPartnerWarmCheckout {
      nodes {
        slug
      }
    }
  }
`

type DynamicInitialInteractionUrlsQueryResponse = {
  allContentfulArticle: { nodes: { slug: string }[] }
  allContentfulBlogCategoryFeed: { nodes: { slug: string }[] }
  allStrapiPartnerWarmCheckout: { nodes: { slug: string }[] }
}

export const useDynamicInitialInteractionUrls = (): Record<
  'blog' | 'partnerships_offer',
  string[]
> => {
  const {
    allContentfulArticle,
    allContentfulBlogCategoryFeed,
    allStrapiPartnerWarmCheckout,
  } = useStaticQuery<DynamicInitialInteractionUrlsQueryResponse>(QUERY)

  return useMemo(() => {
    return {
      blog: [
        ...allContentfulArticle.nodes.map((n) => `/${n.slug}/`),
        ...allContentfulBlogCategoryFeed.nodes.map((n) => `/${n.slug}/`),
      ],
      partnerships_offer: allStrapiPartnerWarmCheckout.nodes.map(
        (n) => `/auth/signup/${n.slug}/`
      ),
    }
  }, [
    allContentfulArticle.nodes,
    allContentfulBlogCategoryFeed.nodes,
    allStrapiPartnerWarmCheckout.nodes,
  ])
}
