export function definedItems<T>(
  t: (T | null | undefined)[] | null | undefined
): T[] {
  const returnList: T[] = []

  if (!t || t === null) {
    return returnList
  }

  for (const el of t) {
    if (el !== null && el !== undefined) {
      returnList.push(el)
    }
  }

  return returnList
}

/**
 * Similar to definedItems, but meant to be passed to filter functions.
 * Acts as type guard asserting that all values are non-nullish after
 * filtering.
 */
export function notEmpty<T>(value: T | null | undefined): value is T {
  return value !== null && value !== undefined
}

export function uniqueItems<T extends Record<string, any>, K extends keyof T>(
  list: T[],
  key: K
): T[] {
  const lookup = {} as Record<K, T>

  for (const el of list) {
    lookup[el[key]] = el
  }
  return Object.values(lookup)
}

export function groupedItems<T extends Record<string, any>, K extends keyof T>(
  list: T[],
  key: K
): Record<T[K], T[]> {
  const lookup = {} as Record<K, T[]>

  for (const el of list) {
    lookup[el[key]] = lookup[el[key]]?.concat(el) || [el]
  }

  return lookup
}

export function buildLookupMap<
  T extends Record<string, any>,
  K extends keyof T
>(list: T[] | null | undefined, k: K) {
  const lookup = {} as Record<T[K], T>

  for (const el of list || []) {
    lookup[el[k]] = el
  }

  return lookup
}

interface NodeConnection<N> {
  edges:
    | ({
        node?: N
      } | null)[]
    | null
}
export function definedNodesFromConnection<N>(nc?: NodeConnection<N> | null) {
  const ret: NonNullable<N>[] = []

  if (!nc) return ret
  for (const edge of nc.edges || []) {
    if (edge?.node) {
      ret.push(edge.node)
    }
  }

  return ret
}
