import gql from 'graphql-tag'

import { createMutationHook } from 'graphql/util/createMutationHook'
import {
  RefreshTokens,
  RefreshTokensVariables,
} from 'types/generated/RefreshTokens'

export const MUTATION_REFRESH_TOKENS = gql`
  mutation RefreshTokens($refreshToken: String!) {
    refreshTokens(refreshToken: $refreshToken) {
      idToken
      accessToken
      refreshToken
    }
  }
`

export const useRefreshTokens = createMutationHook<
  RefreshTokens,
  RefreshTokensVariables
>(MUTATION_REFRESH_TOKENS)
