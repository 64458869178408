import React from 'react'
import { PropTypes as T } from 'prop-types'

import * as styles from './styles'

const propTypes = {
  className: T.string,
  children: T.node.isRequired,
}

const defaultProps = {
  className: '',
}

export default function WarningBox({ className, children }) {
  return (
    <styles.Wrapper className={className}>
      <styles.Icon />
      <div>{children}</div>
    </styles.Wrapper>
  )
}

WarningBox.propTypes = propTypes
WarningBox.defaultProps = defaultProps
