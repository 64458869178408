import { ROUTES } from 'constants/routes'

type KnownFormSlug =
  | 'intake'
  | 'onboarding'
  | 'pet_symptom_checker'
  | 'ef_cancelation'
export const formUrl = (formSlug: KnownFormSlug) => {
  if (
    formSlug === 'intake' ||
    formSlug === 'onboarding' ||
    formSlug === 'pet_symptom_checker'
  ) {
    return '/download-app/'
  } else {
    return `${ROUTES.DYNAMIC_FORM.replace(':formSlug', formSlug)}/`
  }
}

interface ParseURLParamsResult {
  [key: string]: string
}

export function parseURLParams(locationSearch: string): ParseURLParamsResult {
  const params: ParseURLParamsResult = {}
  const search = locationSearch.substr(1)

  if (search.length > 0) {
    search.split('&').forEach((s) => {
      const [key, value] = s.split('=')
      params[key] = value ? decodeURIComponent(value.replace(/\+/g, '%20')) : ''
    })
  }

  return params
}

export function createURLParams(urlParams: Record<string, string>): string {
  let str = ''
  for (const key in urlParams) {
    if (str !== '') {
      str += '&'
    } else {
      str += '?'
    }

    str += `${key}=${encodeURIComponent(urlParams[key])}`
  }

  return str
}

// https://www.store.com/products?id=1234 -> store.com
export function extractURLDomainName(url: string): string {
  // paired down regex from a stackoverflow answer (https://stackoverflow.com/a/25703406)
  const regex = /^(?:https?:\/\/)?(?:[^@/\n]+@)?(?:www\.)?([^:/?\n]+)/gim
  const matches = url.matchAll(regex)

  return Array.from(Array.from(matches)[0])[1]
}

/**
 * Loosely validate a URL `string`.
 */
/**
 * RegExps.
 * A URL must match #1 and then at least one of #2/#3.
 * Use two levels of REs to avoid REDOS.
 */

const protocolAndDomainRE = /^(?:\w+:)?\/\/(\S+)$/

const localhostDomainRE = /^localhost[:?\d]*(?:[^:?\d]\S*)?$/
const nonLocalhostDomainRE = /^[^\s.]+\.\S{2,}$/

export const isUrl = (string: string) => {
  if (typeof string !== 'string') {
    return false
  }

  const match = string.match(protocolAndDomainRE)
  if (!match) {
    return false
  }

  const everythingAfterProtocol = match[1]
  if (!everythingAfterProtocol) {
    return false
  }

  if (
    localhostDomainRE.test(everythingAfterProtocol) ||
    nonLocalhostDomainRE.test(everythingAfterProtocol)
  ) {
    return true
  }

  return false
}
