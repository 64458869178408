import React, { useContext, useEffect, useState } from 'react'
import Cookies, { CookieAttributes } from 'js-cookie'

import { PAWP_DOMAIN } from 'constants/cookies'
import UserContext from 'context/UserContext'
import getInitialInteraction from 'features/_common/components/molecules/UserManagement/getInitialInteraction'
import { useDynamicInitialInteractionUrls } from 'features/_common/components/molecules/UserManagement/useDynamicInitialInteractionUrls'
import { useUpdateUser } from 'graphql/models/user/mutations/updateUser'
import { isDevelopment } from 'utils/env'
import { getAttributionSource } from 'utils/getCheckoutAttributionInput'
import { parseURLParams } from 'utils/url'

export const SHARED_COOKIE_OPTIONS: CookieAttributes =
  typeof window !== 'undefined'
    ? {
        expires: 30,
        path: '/',
        sameSite: 'strict',
        secure: window.location.protocol === 'https:',
        ...(isDevelopment() ? {} : { domain: PAWP_DOMAIN }),
      }
    : {}

export default function AttributionManagement(): JSX.Element {
  useEffect(() => {
    const urlParams = parseURLParams(window.location.search)
    const { awc, utm_source, pid } = urlParams

    if (awc) {
      Cookies.set('aw_click_id', awc, SHARED_COOKIE_OPTIONS)
    }

    if (pid) {
      Cookies.remove('aw_click_id', SHARED_COOKIE_OPTIONS)
    }

    if (utm_source || pid) {
      Cookies.set(
        'attribution',
        JSON.stringify(urlParams),
        SHARED_COOKIE_OPTIONS
      )
    }

    if (document.referrer) {
      Cookies.set('referrer', document.referrer, SHARED_COOKIE_OPTIONS)
    }
  }, [])

  // one shot track utm_source, skip if not auth/guest
  const dynamicInitialInteractions = useDynamicInitialInteractionUrls()
  const [hasFirstAttribution, setHasFirstAttribution] = useState(false)
  const { isAuth } = useContext(UserContext)
  const [updateUser] = useUpdateUser()
  useEffect(() => {
    const source = getAttributionSource()
    const interaction = getInitialInteraction(
      window.location.pathname,
      dynamicInitialInteractions
    )

    if (!hasFirstAttribution && source && interaction && isAuth) {
      Cookies.set(
        'persistedInitialInteraction',
        interaction,
        SHARED_COOKIE_OPTIONS
      )
      updateUser({ variables: { source, interaction } })
    }

    setHasFirstAttribution(true)
  }, [dynamicInitialInteractions, hasFirstAttribution, isAuth, updateUser])

  return <></>
}
