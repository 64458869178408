import styled from 'styled-components'
import Colors from 'components/system/constants/Colors'
import Close from 'features/_common/components/icons/Close/Close'
import { Breakpoints, TABLET } from 'components/system/Container/Container'
import { Z_MODAL } from 'components/system/constants/zIndices'

export const FloatingBox = styled.div`
  position: absolute;
  top: 50px;
  z-index: ${Z_MODAL};
  display: flex;
  justify-content: center;
  width: 100%;
`

export const BoxContent = styled.div`
  min-width: 280px;

  @media ${Breakpoints[TABLET]} {
    min-width: 400px;
  }
`

export const RefreshButton = styled.button`
  border: 0;
  background: 0;
  padding: 5px;
  float: right;
`

export const CloseIcon = styled(Close).attrs({
  fillColor: Colors.emergencyRed,
})`
  width: 15px;
  height: 15px;
`
