import { createMutationHook } from 'graphql/util/createMutationHook'

import { omitEmptyKeys } from 'utils/object'
import { graphql } from '_generated/gql'
import { UpdateUserMutationVariables } from '_generated/gql/graphql'

export const MUTATION_UPDATE_USER = graphql(`
  mutation UpdateUser(
    $email: String
    $phoneNumber: String
    $firstName: String
    $lastName: String
    $source: String
    $interaction: String
    $location: LocationInput
    $avatar: ImageUpload
    $shouldReceiveMarketingSms: Boolean
    $shouldReceiveTransactionalSms: Boolean
    $partner: String
    $partnerCode: String
    $partnerOauthCode: String
  ) {
    updateUser(
      email: $email
      phoneNumber: $phoneNumber
      firstName: $firstName
      lastName: $lastName
      source: $source
      interaction: $interaction
      location: $location
      avatar: $avatar
      shouldReceiveMarketingSms: $shouldReceiveMarketingSms
      shouldReceiveTransactionalSms: $shouldReceiveTransactionalSms
      partner: $partner
      partnerCode: $partnerCode
      partnerOauthCode: $partnerOauthCode
    ) {
      user {
        id
        email
        phoneNumber
        firstName
        lastName
        avatar
        shouldReceiveMarketingSms
        shouldReceiveTransactionalSms
        requiresFullAddress
        insuranceEligibility
        requiresEmailVerification
        location {
          id
          formattedAddress
          address1
          address2
          city
          state
          zip
        }

        ...AccountProfile_User
      }
    }
  }
`)

export const useUpdateUser = createMutationHook(MUTATION_UPDATE_USER)

export const cleanVariables = (
  values: UpdateUserMutationVariables
): UpdateUserMutationVariables => {
  const cleanPhoneNumber =
    values.phoneNumber && `+1${values.phoneNumber.replace(/[^\d]/g, '')}`

  const populatedValues: UpdateUserMutationVariables = omitEmptyKeys({
    ...values,
    phoneNumber: cleanPhoneNumber,
  })

  const populatedLocationValues: UpdateUserMutationVariables['location'] =
    values.location ? omitEmptyKeys(values.location) : {}

  return {
    ...populatedValues,
    location: populatedLocationValues,
  }
}
