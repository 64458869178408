let IdentifyCall: (() => void) | undefined

export function debounceFullstoryIdentify(cb: () => void): void {
  IdentifyCall = () => {
    cb()
    IdentifyCall = undefined
  }
}

export function executeFullstoryIdentify(): void {
  if (IdentifyCall) {
    IdentifyCall()
  }
}
